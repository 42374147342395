define("frontend/components/tab-items/pumps-history", ["exports", "frontend/components/tab-items/tab-item-wrapper"], function (_exports, _tabItemWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      content=(component
        "tab-items/pumps-history/content"
        pump_1=pump_1
        pump_2=pump_2
        pump_3=pump_3
        pump_4=pump_4
        pump_5=pump_5
        pump_6=pump_6
      )
    )
  }}
  */
  {
    "id": "Sxmpm8Z/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"content\"],[[30,[36,6],[\"tab-items/pumps-history/content\"],[[\"pump_1\",\"pump_2\",\"pump_3\",\"pump_4\",\"pump_5\",\"pump_6\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"pump_6\",\"pump_5\",\"pump_4\",\"pump_3\",\"pump_2\",\"pump_1\",\"component\",\"hash\"]}",
    "moduleName": "frontend/components/tab-items/pumps-history.hbs"
  });

  var pumps = {};
  [1, 2, 3, 4, 5, 6].forEach(function (id) {
    var pump_id = "pump_".concat(id);
    var option = "options.".concat(pump_id);
    pumps[pump_id] = Ember.computed(option, {
      get: function get() {
        var _id = this.get(option);

        return Ember.isPresent(_id) ? this.store.peekRecord('pump', _id) : null;
      }
    });
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _tabItemWrapper.default.extend(pumps, {}));

  _exports.default = _default;
});