define("frontend/app", ["exports", "ember-resolver", "ember-load-initializers", "frontend/config/environment", "@formatjs/intl-pluralrules/polyfill", "@formatjs/intl-pluralrules/locale-data/en", "@formatjs/intl-relativetimeformat/polyfill", "@formatjs/intl-relativetimeformat/locale-data/en"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _polyfill, _en, _polyfill2, _en2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Resolver from "frontend/resolver";
  // polyfill for intl
  // Add English data
  // Add English data
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default,

    /*eslint-disable */
    engines: {
      sentryAdvisor: {
        dependencies: {
          externalRoutes: {
            home: "index"
          },
          services: ["alarmToaster", "audience", "event-service", "faye", "fetch", "flashMessages", "globals", "httSession", 'intl', "paperToaster", "right-side-bar", "right-side-bar-locked", "router", "store", "time-formats", "timezone-service", "tos-agreement"]
        }
      }
    }
  });
  /*eslint-enable */

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Ember.Route.reopen({
    pageHeading: function pageHeading()
    /* model */
    {
      return "";
    },
    includePageContent: true,
    afterModel: function afterModel(model
    /* , transition */
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;

      if (self.get("pageHeading")) {
        // We are setting our page title on the application controller.
        self.controllerFor("application").set("pageHeading", self.get("pageHeading")(model));
      }

      self.controllerFor("application").set("includePageContent", self.get("includePageContent"));
      return true; // bubble the event
    },
    defaultTemplate: Ember.computed("routeName", function () {
      return String(this.routeName).replace(/\./g, "/");
    }),
    hasMobileView: false,
    fullscreenOnMobile: false,
    renderTemplate: function renderTemplate()
    /* controller, model */
    {
      if (this.get("globals.isMobile") && this.hasMobileView) {
        var options = this.fullscreenOnMobile && {
          into: "application"
        } || {};
        this.render(this.defaultTemplate + "/-mobile", options);
      } else {
        this.render();
      }
    }
  });
  var _default = App;
  _exports.default = _default;
});