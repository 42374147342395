define("frontend/instance-initializers/subscribe-to-websocket-channels", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @module Initializer
   */

  /**
   * Preloads global application data into the globals service. The globals are provided to the initializer via meta html tags.
   * @class Initializer.load-globals
   */
  function initialize(application) {
    if (_environment.default.environment === "test") return;
    var fayeService = application.lookup('service:faye');
    fayeService.subscribeToGeneral();
  }

  var _default = {
    name: 'subscribeToWebsocketChannels',
    initialize: initialize
  };
  _exports.default = _default;
});