define("frontend/components/ui/inputs/datetime-local", ["exports", "htt-ui/components/ui/inputs/datetime-local"], function (_exports, _datetimeLocal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _datetimeLocal.default;
    }
  });
});