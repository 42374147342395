define("frontend/utils/decorators/installation-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectIfMobile = redirectIfMobile;
  _exports.redirectIfDesktop = redirectIfDesktop;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * @class Transition has mainly three states of `HandlerInfo`s (Each HandlerInfo tries to resolve respective route)
   * UnresolvedHandlerInfoByParam -> eg. has `event_id` in params
   * UnresolvedHandlerInfoByObject -> eg. has `Event` object in context
   * Ref Arch: https://gist.github.com/machty/7698646
   * @param this 
   * @param transition 
   * @param route : Intended route
   */
  function _replaceWith(transition, route) {
    var context = Array.from(new Set(Object.values(transition.resolvedModels))).filter(function (_) {
      return _;
    }); //remove falsey values to get [Customer, Installation] instead [undefined, Customer, Installation, Installation, Installation]

    var handleInfo = transition.handlerInfos.lastObject;

    if (handleInfo.context) {
      // context is the actual model
      context.push(handleInfo.context);
    } else if (handleInfo.params) {
      // params has parsed key-value (mostly only one) pair of route params eg: {event_id: "2"}
      context.push(Object.values(handleInfo.params)[0]);
    }

    try {
      transition.abort();
      this.replaceWith.apply(this, [route].concat(_toConsumableArray(context)));
    } catch (e) {
      console.error("Error occured while redirecting ", e);
    }
  }

  function redirectIfMobile(route) {
    return function decorator(target) {
      target.prototype.reopen({
        beforeModel: function beforeModel(transition) {
          var globals = Ember.getOwner(this).lookup("service:globals");

          if (globals && globals.isMobile) {
            _replaceWith.call(this, transition, route);
          }
        }
      });
    };
  }

  function redirectIfDesktop(route) {
    return function decorator(target) {
      target.prototype.reopen({
        beforeModel: function beforeModel(transition) {
          var globals = Ember.getOwner(this).lookup("service:globals");

          if (globals && !globals.isMobile) {
            _replaceWith.call(this, transition, route);
          }
        }
      });
    };
  }
});