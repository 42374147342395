define("frontend/helpers/object-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.object-at
   */

  /**
   * Used to get an element at array index
   * @method object-at
   * @return Element of an array
   */
  var _default = Ember.Helper.helper(function (params)
  /*, hash*/
  {
    var obj = params[0];
    var index = parseInt(params[1]);

    if (Ember.isNone(index)) {
      return obj;
    } else if (obj instanceof Array) {
      if (index < obj.length) {
        return obj[index];
      }
    }
  });

  _exports.default = _default;
});