define("frontend/utils/set-threshold-alert-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setThresholdToAlarm = setThresholdToAlarm;
  _exports.setThresholdToAlert = setThresholdToAlert;
  _exports.setThresholdToReport = setThresholdToReport;
  _exports.setThresholdToInactive = setThresholdToInactive;
  _exports.thresholdAlertType = thresholdAlertType;
  _exports.setThresholdAlertType = setThresholdAlertType;

  function setThresholdToAlarm(analog, thresholdNumber) {
    var prefix = "threshold".concat(thresholdNumber);
    analog.set("".concat(prefix, "Alarm"), true);
    analog.set("".concat(prefix, "Active"), true);
    analog.set("".concat(prefix, "Alert"), false);
  }

  function setThresholdToAlert(analog, thresholdNumber) {
    var prefix = "threshold".concat(thresholdNumber);
    analog.set("".concat(prefix, "Alarm"), true);
    analog.set("".concat(prefix, "Active"), true);
    analog.set("".concat(prefix, "Alert"), true);
  }

  function setThresholdToReport(analog, thresholdNumber) {
    var prefix = "threshold".concat(thresholdNumber);
    analog.set("".concat(prefix, "Alarm"), false);
    analog.set("".concat(prefix, "Active"), true);
    analog.set("".concat(prefix, "Alert"), false);
  }

  function setThresholdToInactive(analog, thresholdNumber) {
    var prefix = "threshold".concat(thresholdNumber);
    analog.set("".concat(prefix, "Alarm"), false);
    analog.set("".concat(prefix, "Active"), false);
    analog.set("".concat(prefix, "Alert"), false);
  }

  function thresholdAlertType(analog, thresholdNumber) {
    var prefix = "threshold".concat(thresholdNumber);
    var alarm = analog.get("".concat(prefix, "Alarm"));
    var alert = analog.get("".concat(prefix, "Alert"));
    var active = analog.get("".concat(prefix, "Alert"));

    if (active === false) {
      return "inactive";
    } else if (alarm === false) {
      return "report";
    } else if (alert === false) {
      return "alarm";
    } else {
      return "alert";
    }
  }

  function setThresholdAlertType(analog, thresholdNumber, alertType) {
    switch (alertType) {
      case "alarm":
        setThresholdToAlarm(analog, thresholdNumber);
        break;

      case "alert":
        setThresholdToAlert(analog, thresholdNumber);
        break;

      case "report":
        setThresholdToReport(analog, thresholdNumber);
        break;

      default:
        setThresholdToInactive(analog, thresholdNumber);
    }
  }
});