define("frontend/instance-initializers/load-countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @module Initializer
   */

  /**
   * Preloads country data into the globals service. 
   * @class Initializer.load-countries
   */
  function initialize(application) {
    var globalsService = application.lookup('service:globals'); // load countries and regions to globals

    globalsService.loadCountriesAndRegions();
  }

  var _default = {
    name: 'load-countries',
    initialize: initialize
  };
  _exports.default = _default;
});