define("frontend/validators/password-capital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePasswordCapital = validatePasswordCapital;

  // @ts-ignore
  function validatePasswordCapital() {
    return function (_key, newValue) {
      return newValue !== newValue.toLowerCase() || "Password must contain at least 1 capital letter";
    };
  }
});