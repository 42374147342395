define("frontend/components/paper-tabs-htt", ["exports", "ember-paper/components/paper-tabs"], function (_exports, _paperTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperTabs.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var selectedTab = this.get('_selectedTab');

      if (selectedTab) {
        var selectedTabLeft = selectedTab.get('left');

        if (selectedTabLeft) {
          this.set('paginationStyle', Ember.String.htmlSafe("transform: translate3d(-".concat(selectedTabLeft, "px, 0px, 0px);")));
        }
      }
    }
  });

  _exports.default = _default;
});