define("frontend/helpers/nested-path-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.nested-path-of
   */

  /**
   * Takes two paths and checks if one is nested under other.
   * @return {Boolean}
   */
  var _default = Ember.Helper.helper(function (params) {
    var baseUrl = String(params[1]).split('?')[0];
    return baseUrl.match(RegExp(params[0]));
  });

  _exports.default = _default;
});