define("frontend/validators/analog-high-val", ["exports", "frontend/utils/analog-sensor-types"], function (_exports, _analogSensorTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return function (key, newValue, _oldValue, changes, content) {
      var highVal = newValue;
      var sensorOut = Ember.get(changes, 'sensorOut') || Ember.get(content, 'sensorOut');
      var lowVal = Ember.get(changes, 'lowVal') || Ember.get(content, 'lowVal'); //no validation if sensorOut is of types

      if (_analogSensorTypes.default.isNotScaled(sensorOut)) return true; //error if lowVal is null

      if (Ember.isNone(highVal)) return "".concat(key, " must be set");
      highVal = parseInt(highVal);
      lowVal = parseInt(lowVal);
      if (sensorOut === "current" && (highVal < 4 || highVal > 20)) return "".concat(key, " must be between 4-20 ma");
      if (sensorOut === "voltage" && (highVal < 0 || highVal > 5)) return "".concat(key, " must be between 0-5 V");
      if (lowVal >= highVal) return "".concat(key, " must be above Low Val");
      return true;
    };
  }
});