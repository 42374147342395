define("frontend/routes/customer/pump-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service('can'),
    beforeModel: function beforeModel() {
      var pumpGroupAbility = this.can.abilityFor("pump-group", null);

      if (!pumpGroupAbility.get('canCreate')) {
        var customer = this.modelFor('customer');
        this.transitionTo('customer.controls', customer);
      }
    },
    model: function model(params) {
      return this.store.findRecord('pump-group', params.pump_group_id);
    },
    actions: {
      error: function error() {
        var customer = this.modelFor('customer');
        this.transitionTo('customer.pump-groups', customer);
      },
      willTransition: function willTransition() {
        this.controller.model.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});