define("frontend/templates/customer/installations/details/inst/settings/io/rain-counter/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n8gt5zwZ",
    "block": "{\"symbols\":[\"toolbar\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"layout-column\"],[14,5,\"overflow: auto;\"],[12],[2,\"\\n  \"],[8,\"paper-toolbar\",[],[[\"@primary\",\"@class\"],[true,[30,[36,0],[[32,0,[\"globals\",\"isSmall\"]],\"md-menu-toolbar\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"tools\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,0,[\"goBackToSettings\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"keyboard_backspace\"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Editing \"],[1,[32,2,[\"name\"]]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[8,\"forms/rain-counter-form\",[],[[\"@rainCounter\",\"@onCancel\",\"@afterSave\"],[[32,2],[32,0,[\"goBackToSettings\"]],[32,0,[\"goBackToSettings\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/settings/io/rain-counter/edit.hbs"
  });

  _exports.default = _default;
});