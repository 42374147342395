define("frontend/components/forms/dashboard-element-reports/digital-output-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::DigitalOutput
    @changeset={{@changeset}}
    @attribute={{"digitalOutput"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @diLabel={{"Digital Output"}}
  />
  */
  {
    "id": "HCnQRxqc",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/digital-output\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@diLabel\"],[[32,1],\"digitalOutput\",[32,2],\"installation\",\"Digital Output\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/digital-output-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});