define("frontend/services/can", ["exports", "ember-can/services/abilities"], function (_exports, _abilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _abilities.default;
    }
  });
});