define("frontend/templates/distributor/alarm-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nWwwzQsf",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/distributor\",[],[[\"@title\",\"@distributor\"],[[30,[36,1],[[30,[36,0],[\"alarmSettings\"],null],\" - \",[32,0,[\"alarmGroup\",\"name\"]]],null],[32,0,[\"distributor\"]]]],null],[2,\"\\n\\n\"],[8,\"page-content\",[[24,0,\"position-static\"]],[[\"@padding\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n   \"],[8,\"lists/alarm-group-contact-links\",[],[[\"@editAgcl\",\"@deleteAgcl\",\"@toggleSuspendAgcl\",\"@alarmGroupContactLinks\"],[[32,0,[\"edit\"]],[32,0,[\"delete\"]],[32,0,[\"toggleSuspend\"]],[32,0,[\"alarmGroupContactLinks\"]]]],null],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[\"create alarmGroupContactLink\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-button\",[],[[\"@raised\",\"@fab\",\"@class\",\"@onClick\"],[true,true,\"green-btn bottom-right-btn absolute-position\",[32,0,[\"create\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"add\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"can\",\"if\"]}",
    "moduleName": "frontend/templates/distributor/alarm-group.hbs"
  });

  _exports.default = _default;
});