define("frontend/validators/pump-valve-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return function (_key, newValue, _oldValue, _changes, content) {
      // get number and ensure it's an int
      var number = newValue;
      if (number) number = parseInt(number);
      var installation = content.get("installation");
      var PumpValveSameException = {};
      if (number <= 0) return "Number should a positive integer";

      try {
        installation.get("pumps").forEach(function (pump) {
          if (pump.get("id") !== content.get("id") && number == pump.get("number")) {
            throw PumpValveSameException;
          }
        });
        installation.get("valves").forEach(function (valve) {
          if (valve.get("id") !== content.get("id") && number == valve.get("number")) {
            throw PumpValveSameException;
          }
        });
      } catch (e) {
        if (e !== PumpValveSameException) throw e;
        return "Number must be unique";
      }

      return true;
    };
  }
});