define("frontend/adapters/sentry-advisor-site", ["exports", "sentry-advisor/adapters/sentry-advisor-site"], function (_exports, _sentryAdvisorSite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sentryAdvisorSite.default;
    }
  });
});