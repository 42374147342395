define("frontend/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('slideText'), this.use('toUp'));
    this.transition(this.hasClass("pages"), this.toValue(function (newValue, oldValue) {
      return newValue > oldValue;
    }), this.use('toLeft', {
      duration: 500
    }), this.reverse('toRight', {
      duration: 500
    }));
    this.transition(this.hasClass("side-nav-form"), this.toValue(true), this.use('toUp', {
      duration: 800,
      easing: 'spring'
    }), this.reverse('toDown', {
      duration: 800,
      easing: 'spring'
    }));
    this.transition(this.hasClass("btn-scale"), this.useAndReverse('scale', {
      duration: 200
    }));
    this.transition(this.childOf('.md-subheader-content'), this.use('toUp'));
    this.transition(this.hasClass('liquid-cross-fade'), this.use('crossFade', {
      duration: 250 * 2
    }));
  }
});