define("frontend/initializers/custom-injections", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject("route", "globals", "service:globals");
    application.inject("controller", "intl", "service:intl");
    application.inject("component", "intl", "service:intl");
    application.inject("route", "intl", "service:intl");
    application.inject("model", "intl", "service:intl");

    _model.default.reopen({
      get adapter() {
        return this.store && this.store.adapterFor(this.constructor.modelName);
      }

    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});