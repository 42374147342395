define("frontend/components/forms/dashboard-element-reports/gas-odor-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"gas"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"Gas"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"odor"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"Odor"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"altGas"}}
    @customer={{@customer}}
    @label={{"Alt Gas"}}
    @instLabel={{"installation"}}
  />
  */
  {
    "id": "4fq+AH+r",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"gas\",[32,2],\"installation\",\"Gas\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"odor\",[32,2],\"installation\",\"Odor\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\",\"@instLabel\"],[[32,1],\"altGas\",[32,2],\"Alt Gas\",\"installation\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/gas-odor-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});