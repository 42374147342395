define("frontend/components/address-dialog", ["exports", "htt-ui/components/address-dialog"], function (_exports, _addressDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _addressDialog.default;
    }
  });
});