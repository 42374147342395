define("frontend/utils/palattes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable */
  // Auto Generated file from exportSass.
  var _default = {
    "eone-primary": {
      '50': "#e1f4ff",
      '100': "#b2e3fd",
      '200': "#7fd1fb",
      '300': "#4cbff9",
      '400': "#24b1f7",
      '500': "#09529b",
      // eone-primary
      '600': "#0596e6",
      '700': "#0983d1",
      '800': "#0872bd",
      '900': "#09529b",
      // eone-primary
      'A100': "#5bbdff",
      'A200': "#28a8ff",
      'A400': "#0091f4",
      'A700': "#0082db",
      'default': "#09529b",
      "contrastDefaultColor": "rgba(255, 255, 255, 0.87)",
      "contrastLightColors": ['500', '600', '700', '800', '900'],
      "contrastStrongLightColors": ['500', '600', '700']
    },
    "eone-accent": {
      '50': "#e0f4fd",
      '100': "#b0e2f9",
      '200': "#7bd0f5",
      '300': "#43bdf1",
      '400': "#00afef",
      '500': "#00afef",
      // eone-primary
      '600': "#0094de",
      '700': "#0081ca",
      '800': "#0070b6",
      '900': "#005195",
      // eone-primary
      'A100': "#eff9ff",
      'A200': "#bce5ff",
      'A400': "#89d1ff",
      'A700': "#6fc8ff",
      'default': "#00afef",
      "contrastDefaultColor": "rgba(255, 255, 255, 0.87)",
      "contrastLightColors": ['500', '600', '700', '800', '900'],
      "contrastStrongLightColors": ['500', '600', '700']
    },
    "eone-background": {
      '50': '#ffffff',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#ffffff',
      'A200': '#000000',
      'A400': '#303030',
      'A700': '#616161',
      "contrastDefaultColor": "rgba(255, 255, 255, 0.87)",
      "contrastLightColors": ['600', '700', '800', '900', 'A200', 'A400', 'A700']
    }
  };
  _exports.default = _default;
});