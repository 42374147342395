define("frontend/components/alarm-list-item", ["exports", "htt-ui/components/alarm-list-item"], function (_exports, _alarmListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _alarmListItem.default;
    }
  });
});