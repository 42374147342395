define("frontend/templates/customer/installations/details/inst/settings/io/rain-counter/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OsMaHYm4",
    "block": "{\"symbols\":[\"tabs\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"layout-column flex-grow\"],[12],[2,\"\\n    \"],[8,\"io-header\",[],[[\"@model\"],[[32,2]]],null],[2,\"\\n\\n    \"],[8,\"paper-tabs\",[],[[\"@primary\",\"@stretch\",\"@selected\"],[false,[32,0,[\"globals\",\"isSmall\"]],0]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"tab\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            Data\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"tab\"]],[],[[\"@href\"],[[30,[36,0],[\"customer.installations.details.inst.settings.io.rainCounter.settings\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            Settings\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n    \"],[8,\"paper-content\",[[24,0,\"dark-content flex-grow layout-column\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"charts/rain-counter\",[[24,0,\"full-height\"]],[[\"@model\",\"@groupBy\"],[[32,0,[\"model\"]],\"quarter-hourly\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"href-to\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/settings/io/rain-counter/data.hbs"
  });

  _exports.default = _default;
});