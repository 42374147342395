define("frontend/templates/customer/controls/pump-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W47FKpMW",
    "block": "{\"symbols\":[\"pumpGroup\"],\"statements\":[[6,[37,6],[[30,[36,5],[\"create pump-group\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"create-fab-button\",[],[[\"@onClick\"],[[30,[36,4],[\"customer.create-pump-group\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[32,0,[\"pumpGroups\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"layout-row flex-wrap overflow-y-auto\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"pumpGroups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"controls/pump-groups\",[],[[\"@pumpGroup\",\"@onClick\"],[[32,1],[30,[36,1],[[32,0,[\"viewDetails\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,\"sytle\",\"font-size: 20px; margin: 8px;\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"noItems\"],[[\"items\"],[[30,[36,0],[\"pumpGroup.many\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"-track-array\",\"each\",\"transition-to\",\"can\",\"if\"]}",
    "moduleName": "frontend/templates/customer/controls/pump-groups.hbs"
  });

  _exports.default = _default;
});