define("frontend/templates/customer/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J3LQOPMz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"customer\"]],\"Users\"]],null],[2,\"\\n\"],[8,\"paper-content\",[[24,0,\"layout-row flex position-initial\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-grow\"],[12],[2,\"\\n    \"],[8,\"lists/users-list\",[],[[\"@users\",\"@editUser\"],[[32,0,[\"users\"]],[32,0,[\"editUser\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[\"create user\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-button\",[],[[\"@raised\",\"@fab\",\"@class\",\"@onClick\"],[true,true,\"green-btn bottom-right-btn absolute-position\",[32,0,[\"createUser\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        add\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"can\",\"if\"]}",
    "moduleName": "frontend/templates/customer/users.hbs"
  });

  _exports.default = _default;
});