define("frontend/models/dashboard-eleme", ["exports", "htt-shared-models/models/dashboard-eleme"], function (_exports, _dashboardEleme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dashboardEleme.default;
    }
  });
});