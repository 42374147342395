define("frontend/helpers/get-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.get-property
   */

  /**
   * Used in select input to get property or return obj if key is null
   * @method get-property
   * @return property of Ember object
   */
  var _default = Ember.Helper.helper(function (params)
  /*, hash*/
  {
    var obj = params[0];
    var key = params[1];

    if (Ember.isNone(key)) {
      return obj;
    } else if (obj instanceof Ember.Object) {
      return obj.get(key);
    } else {
      var emberObj = Ember.Object.create(obj);
      return emberObj.get(key);
    }
  });

  _exports.default = _default;
});