define("frontend/components/dialogs/control-rule-action-form/action-type-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperAddons::SearchSelect
     data-test-action-type-select
    @class="flex"
    @options={{@actionTypes}}
    @autofocus={{true}}
    @field={{"name"}}
    @label={{t "type"}}
    @onChange={{@onSelect}}
    @paperItemClass={{"md-2-line"}} as |option searchText error|
  >
    {{#if option}}
      <div class="md-list-item-text">
        <h3>
          {{option.name}}
        </h3>
        <p>
          {{option.description}}
        </p>
      </div>
    {{/if}}
    {{#if error}}
      <h4>
        {{t "no-items-found" items=(t "types")}}
      </h4>
    {{/if}}
  </PaperAddons::SearchSelect>
  */
  {
    "id": "gMKLLFUn",
    "block": "{\"symbols\":[\"option\",\"searchText\",\"error\",\"@actionTypes\",\"@onSelect\"],\"statements\":[[8,\"paper-addons/search-select\",[],[[\"@class\",\"@options\",\"@autofocus\",\"@field\",\"@label\",\"@onChange\",\"@paperItemClass\"],[\"flex\",[32,4],true,\"name\",[30,[36,0],[\"type\"],null],[32,5],\"md-2-line\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[32,1,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"no-items-found\"],[[\"items\"],[[30,[36,0],[\"types\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "frontend/components/dialogs/control-rule-action-form/action-type-select.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});