define("frontend/models/digital-input-selector", ["exports", "htt-shared-models/models/digital-input-selector"], function (_exports, _digitalInputSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _digitalInputSelector.default;
    }
  });
});