define("frontend/validations/pump-valve-identity", ["exports", "ember-changeset-validations/validators", "frontend/validators/pump-valve-location"], function (_exports, _validators, _pumpValveLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var PumpValveIdentityValidation = {
    name: (0, _validators.validatePresence)(true),
    number: [(0, _pumpValveLocation.default)(), (0, _validators.validatePresence)(true)]
  };
  var _default = PumpValveIdentityValidation;
  _exports.default = _default;
});