define("frontend/instance-initializers/check-is-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @module Initializer
   */

  /**
   * Preloads mobile device check into globals service.
   * @class Initializer.check-mobile
   */
  function initialize(application) {
    var globals = application.lookup('service:globals');
    var geocode = application.lookup('service:geocode');

    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i)) {
      globals.set("isMobile", true);
    } else {
      globals.set("isMobile", false);
    }
    /**
     *  Supposed to perform geocode check only when user wants to use geo location based search
     */


    if (globals.get("isMobile")) {
      if (navigator && navigator.geolocation) {
        globals.set('allowedToAccessLocation', true);
        geocode.subscribe();
      } else {
        // console.error('Geolocation is not supported on this device.');
        globals.set('allowedToAccessLocation', false);
      }
    }
  }

  var _default = {
    name: 'checkIsMobile',
    initialize: initialize
  };
  _exports.default = _default;
});