define("frontend/components/paper-step-body", ["exports", "ember-paper-stepper/components/paper-step-body"], function (_exports, _paperStepBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paperStepBody.default;
    }
  });
});