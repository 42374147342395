define("frontend/templates/query-manager-view-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03+KJMc8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex layout-column\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"main\"],[12],[2,\"\\n  \"],[8,\"app-bars/htt\",[],[[\"@title\"],[\"Query Manager Test Page\"]],null],[2,\"\\n  \"],[8,\"query-manager-view\",[],[[\"@queryManager\",\"@columns\",\"@filterComponent\",\"@listItemComponent\",\"@onItemClick\",\"@createComponent\"],[[32,0,[\"queryManager\"]],[32,0,[\"columns\"]],\"alarms-filters\",[30,[36,0],[\"alarm-list-item\"],[[\"compressed\"],[true]]],[32,0,[\"onItemClick\"]],[30,[36,0],[\"ui/buttons/test-speed-dial\"],[[\"handleServiceLog\",\"handleTechnicalSupportLog\"],[[32,0,[\"handleServiceLog\"]],[32,0,[\"handleTechnicalSupportLog\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"alarms-download-button\",[],[[\"@installation\",\"@customer\",\"@iconButton\"],[[32,0,[\"installation\"]],[32,0,[\"customer\"]],true]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "frontend/templates/query-manager-view-test.hbs"
  });

  _exports.default = _default;
});