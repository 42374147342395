define("frontend/components/paper-expansion-panel", ["exports", "ember-paper-expansion-panel/components/paper-expansion-panel"], function (_exports, _paperExpansionPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paperExpansionPanel.default;
    }
  });
});