define("frontend/validations/phone-number", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    fullNumber: (0, _validators.validateFormat)({
      regex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
      // eslint-disable-line no-useless-escape
      message: "Phone number is not valid"
    })
  };
  _exports.default = _default;
});