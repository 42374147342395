define("frontend/overrides/route", [], function () {
  "use strict";

  Ember.Route.reopen({
    pageHeading: function pageHeading()
    /* model */
    {
      return "";
    },
    includePageContent: true,
    afterModel: function afterModel(model
    /* , transition */
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;

      if (self.get('pageHeading')) {
        // We are setting our page title on the application controller.
        self.controllerFor('application').set('pageHeading', self.get('pageHeading')(model));
      }

      self.controllerFor('application').set('includePageContent', self.get('includePageContent'));
      return true; // bubble the event
    }
  });
});