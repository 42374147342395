define("frontend/helpers/array-slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.array-slice
   */

  /**
   * Used to slice an array
   * @method array-slice
   * @return Sliced elements from an array
   */
  var _default = Ember.Helper.helper(function (params) {
    return params[0].slice(params[1]);
  });

  _exports.default = _default;
});