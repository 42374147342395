define("frontend/templates/distributor/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "moCL/PfR",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"app-bars/distributor\",[],[[\"@title\",\"@distributor\"],[[30,[36,0],[\"user.group-users\"],[[\"group\"],[[32,0,[\"distributor\",\"name\"]]]]],[32,0,[\"distributor\"]]]],null],[2,\"\\n\\n\"],[8,\"page-content\",[],[[\"@padding\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"lists/users-list\",[],[[\"@users\",\"@editUser\",\"@resetPassword\"],[[32,0,[\"users\"]],[32,0,[\"editUser\"]],[32,0,[\"resetPassword\"]]]],null],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[\"create user\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-button\",[[24,0,\"green-btn bottom-right-btn absolute-position\"]],[[\"@raised\",\"@fab\",\"@onClick\"],[true,true,[32,0,[\"create\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"add\"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"can\",\"if\"]}",
    "moduleName": "frontend/templates/distributor/users.hbs"
  });

  _exports.default = _default;
});