define("frontend/helpers/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function range(params, namedArgs) {
    var arr = [];
    var start = namedArgs.start,
        end = namedArgs.end,
        step = namedArgs.step,
        type = namedArgs.type; //Convert string to number

    var startNum = parseInt(start);
    var stepNum = parseInt(step);
    var endNum = parseInt(end);

    if (!stepNum || startNum > endNum && stepNum > 1 || startNum < endNum && stepNum < 1) {
      console.error("Infinite loop aborted");
      return;
    }

    if (startNum >= endNum) for (var it = startNum; it >= endNum; it += stepNum) {
      arr.push(it);
    } else for (var _it = startNum; _it <= endNum; _it += stepNum) {
      arr.push(_it);
    }
    return type === "string" ? arr.map(function (num) {
      if (num < 10) return '0' + num;
      return '' + num;
    }) : arr;
  });

  _exports.default = _default;
});