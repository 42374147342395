define("frontend/instance-initializers/set-audience", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var audienceService = appInstance.lookup("service:audience");
    audienceService.setAudience(_environment.default.defaultAudience);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});