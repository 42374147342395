define("frontend/utils/changeset-alias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changesetAlias = changesetAlias;
  _exports.changesetErrorsAlias = changesetErrorsAlias;

  /**
   * Alias attribute with key saved at attributeKeyPath on changeset saved at changesetPath
   * 
   * @param changesetPath 
   * @param attributeKeyPath 
   * @returns  
   */
  function changesetAlias(changesetPath, attributeKeyPath) {
    // eslint-disable-next-line
    return function (target, key) {
      return {
        set: function set(value) {
          var fullpath = [changesetPath, Ember.get(this, attributeKeyPath)].join('.');
          Ember.set(this, fullpath, value);
        },
        get: function get() {
          var attributeKey = Ember.get(this, attributeKeyPath);
          return Ember.get(Ember.get(this, changesetPath), attributeKey);
        },
        enumerable: true,
        configurable: true
      };
    };
  }
  /**
   * Alias the validation errors for attribute with key saved in attributeKeyPath on changeset saved at changesetPath
   * 
   * @param changesetPath 
   * @param attributeKeyPath 
   * @returns  
   */


  function changesetErrorsAlias(changesetPath, attributeKeyPath) {
    // eslint-disable-next-line
    return function (target, key) {
      return {
        get: function get() {
          var attributeName = Ember.get(this, attributeKeyPath);
          var errorsPath = [changesetPath, "error", attributeName, "validation"].join('.');
          var errStr = Ember.get(this, errorsPath);
          var errArray = errStr ? [errStr] : [];
          return errArray;
        },
        enumerable: true,
        configurable: true
      };
    };
  }
});