define("frontend/templates/customer/installations/details/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJz1AdJl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"globals\",\"isSmall\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"flex-grow layout-row layout-align-center-center\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    select installation for details\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "moduleName": "frontend/templates/customer/installations/details/index.hbs"
  });

  _exports.default = _default;
});