define("frontend/validations/counter-identity", ["exports", "ember-changeset-validations/validators", "frontend/validators/counter-location"], function (_exports, _validators, _counterLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    name: (0, _validators.validatePresence)(true),
    number: [(0, _counterLocation.default)(), (0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});