define("frontend/router", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    metrics: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: "/",
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on("routeDidChange", function () {
        Ember.run.scheduleOnce("afterRender", _this, _this._trackPage);

        _this._trackPage();
      });
    },
    _trackPage: function _trackPage() {
      var page = this.url;
      var title = this.getWithDefault("currentRouteName", "unknown");
      this.metrics.trackPage({
        page: page,
        title: title
      });
    }
  });
  Router.map(function () {
    this.route('cognito-redirect');
    this.route("authenticated", {
      path: ""
    }, function () {
      this.route("installation", {
        path: "installation/:installation_id",
        resetNamespace: true
      });
      this.route("users", {
        resetNamespace: true
      });
      this.route("hardware-types", {
        resetNamespace: true
      }, function () {
        this.route("code-versions", function () {
          this.route("create");
          this.route("code-version", {
            path: "/code-version/:codeversion_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("modem-types", function () {
          this.route("create");
          this.route("modem-type", {
            path: "/modem-type/:modemtype_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("stackup-types", function () {
          this.route("create");
          this.route("stackup-type", {
            path: "/stackup-type/:stackuptype_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("rtu-types", function () {
          this.route("create");
          this.route("rtu-type", {
            path: "/rtu-type/:rtutype_id"
          }, function () {
            this.route("edit");
          });
        });
      });
      this.route("hardware", {
        resetNamespace: true
      }, function () {
        this.route("stackups", function () {
          this.route("create");
          this.route("stackup", {
            path: "/stackup/:stackup_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("rtus", function () {
          this.route("create");
          this.route("rtu", {
            path: "/rtu/:rtu_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("modems", function () {
          this.route("create");
          this.route("modem", {
            path: "/modem/:modem_id"
          }, function () {
            this.route("edit");
          });
        });
      });
      this.route("customer", {
        resetNamespace: true,
        path: "/customer/:customer_id"
      }, function () {
        this.route("logs", function () {
          this.route("service-log", {
            path: "/service-log/:log_id"
          }, function () {
            this.route("edit");
          });
          this.route("technical-support-log", {
            path: "/technical-support-log/:log_id"
          }, function () {
            this.route("edit");
          });
          this.route("new", function () {
            this.route("service-log");
            this.route("technical-support-log");
          });
        });
        this.route("alarms", function () {
          this.route("alarm", {
            path: "/id/:alarm_id"
          });
        });
        this.route("alarmGroup", {
          path: "/alarm_group/:alarm_group_id"
        });
        this.route("users");
        this.route("controls", function () {
          this.route("pump-groups");
        });
        this.route("create-pump-group");
        this.route("control", {
          path: "/control/:control_id"
        });
        this.route("gpgNeighborhoods", {
          path: "/neighborhoods/"
        });
        this.route("gpgNeighborhood", {
          path: "/neighborhood/:gpg_neighborhood_id"
        }, function () {
          this.route("map");
          this.route("collectors");
          this.route("guardians");
          this.route("alarms", function () {
            this.route("alarm", {
              path: "/alarm/:alarm_id"
            });
          });
        });
        this.route("gpgRemoteInstallation", {
          path: "/guardian/:gpg_remote_installation_id"
        }, function () {
          this.route("statistics");
          this.route("alarms", function () {
            this.route("alarm", {
              path: "/alarm/:alarm_id"
            });
          });
        });
        this.route("guardians", {
          path: "/guardians/"
        });
        this.route("installations", {
          path: "/installations/"
        }, function () {
          this.route("map");
          this.route("details", {
            path: "/d/"
          }, function () {
            this.route('create');
            this.route("inst", {
              path: "/inst/:installation_id"
            }, function () {
              this.route('rtuConfigs', {
                path: "/rtu-configs"
              }, function () {
                this.route('rtuConfigDetail', {
                  path: "/:rtu_config_id"
                });
              });
              this.route("alarms", function () {
                this.route("alarm", {
                  path: "/alarm/:alarm_id"
                });
              });
              this.route("serviceLogs", {
                path: "/service-logs"
              }, function () {
                this.route("new"), this.route("log", {
                  path: "log/:service_log_id"
                });
              });
              this.route("settings", function () {
                this.route('telemetryData', {
                  path: '/telemetry-data'
                }, function () {
                  this.route('telemetryDataDetail', {
                    path: "/detail/:telemetry_data_id"
                  });
                });
                this.route("rtuMessages", {
                  path: "/rtu-messages"
                });
                this.route("general");
                this.route('edit');
                this.route("io", function () {
                  this.route("analog", {
                    path: "/analog/:analog_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("analogOutput", {
                    path: "/analog-output/:analog_output_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route('create', function () {
                    this.route('analog-input');
                    this.route('analog-output');
                    this.route('counter');
                    this.route('digital-input');
                    this.route('digital-output');
                    this.route('plc-register');
                    this.route('pump');
                    this.route('rain-counter');
                    this.route('valve');
                  });
                  this.route("digitalInput", {
                    path: "/digital-input/:digital_input_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route('edit');
                  });
                  this.route("digitalOutput", {
                    path: "/digital-output/:digital_output_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("plcRegister", {
                    path: "/plc-register/:plc_register_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("counter", {
                    path: "/counter/:counter_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("rainCounter", {
                    path: "/rain-counter/:rain_counter_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("pump", {
                    path: "/pump/:pump_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("valve", {
                    path: "/valve/:valve_id"
                  }, function () {
                    this.route("settings");
                    this.route("data");
                    this.route("edit");
                  });
                  this.route("new");
                });
                this.route("legacyControls", {
                  path: "/legacy-controls"
                });
              });
              this.route("logs", function () {
                this.route("service-log", {
                  path: "/service-log/:log_id"
                }, function () {
                  this.route("edit");
                });
                this.route("technical-support-log", {
                  path: "/technical-support-log/:log_id"
                }, function () {
                  this.route("edit");
                });
                this.route("new", function () {
                  this.route("service-log");
                  this.route("technical-support-log");
                });
              });
            });
          });
        });
        this.route("dashboardDisplay", {
          path: "/dashboard_display/:dashboard_display_id"
        });
        this.route("pump-group", {
          path: "/pump-group/:pump_group_id"
        });
        this.route("settings");
      });
      this.route("distributor", {
        resetNamespace: true,
        path: "/distributor/:distributor_id"
      }, function () {
        this.route("alarmGroup", {
          path: "/alarm_group/:alarm_group_id"
        });
        this.route("alarms", function () {
          this.route("alarm", {
            path: "/id/:alarm_id"
          });
        });
        this.route("users");
        this.route("installations");
        this.route("subDistributors", {
          path: "/sub-distributors"
        });
        this.route("customers");
        this.route("statistics");
      });
      this.route("distributors", {
        resetNamespace: true
      });
      this.route("installations", {
        resetNamespace: true
      });
      this.route("unknown-messages", {
        resetNamespace: true
      });
      this.route("query-manager-table-test", {
        resetNamespace: true
      });
    });
    this.route("index");
    this.route("version");
    this.route("notFound", {
      path: "/*path"
    });
    this.mount("sentry-advisor", {
      path: "/sa"
    });
    this.route("query-manager-view-test");
    this.route("query-manager-test");
  });
  var _default = Router;
  _exports.default = _default;
});