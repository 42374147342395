define("frontend/validations/analog-thresholds", ["exports", "ember-changeset-validations/validators", "frontend/validators/analog-threshold-level"], function (_exports, _validators, _analogThresholdLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    threshold1Level: [(0, _validators.validatePresence)(true), (0, _analogThresholdLevel.default)()],
    threshold1Type: (0, _validators.validatePresence)(true),
    threshold2Level: [(0, _validators.validatePresence)(true), (0, _analogThresholdLevel.default)()],
    threshold2Type: (0, _validators.validatePresence)(true),
    threshold3Level: [(0, _validators.validatePresence)(true), (0, _analogThresholdLevel.default)()],
    threshold3Type: (0, _validators.validatePresence)(true),
    threshold4Level: [(0, _validators.validatePresence)(true), (0, _analogThresholdLevel.default)()],
    threshold4Type: (0, _validators.validatePresence)(true),
    deltaAlarmThreshold: (0, _validators.validateNumber)({
      allowBlank: true,
      gt: 0
    })
  };
  _exports.default = _default;
});