define("frontend/validators/password-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePasswordNumber = validatePasswordNumber;

  // @ts-ignore
  function validatePasswordNumber() {
    return function (_key, newValue) {
      return /\d/.test(newValue) || "Password must contain at least 1 number";
    };
  }
});