define("frontend/components/forms/dashboard-element-reports/rain-counter-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::RainCounter
    @changeset={{@changeset}}
    @attribute={{"rainCounter"}}
    @customer={{@customer}}
    @label={{"Rain Counter"}}
    @instLabel={{"installation"}}
  />
  */
  {
    "id": "xT63MhyI",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/rain-counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\",\"@instLabel\"],[[32,1],\"rainCounter\",[32,2],\"Rain Counter\",\"installation\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/rain-counter-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});