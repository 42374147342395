define("frontend/templates/customer/gpg-remote-installation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yKWh5k4r",
    "block": "{\"symbols\":[\"tabs\",\"route\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"globals\",\"isMedium\"]],[32,0,[\"selectedRoute\"]]],null]],null,[[\"default\"],[{\"statements\":[[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"customer\"]],[32,0,[\"gpgRemoteInstallation\",\"label\"]]]],null],[2,\"\\n\\n\"],[8,\"paper-tabs\",[],[[\"@stretch\",\"@selected\",\"@primary\",\"@onChange\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"selectedRoute\"]],true,[32,0,[\"transitionToRoute\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"tabRoutes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"tab\"]],[],[[\"@value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"label\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,5],[[30,[36,4],null,[[\"class\"],[\"gpg-remote-installation-liquid-outlet\"]]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"or\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "frontend/templates/customer/gpg-remote-installation.hbs"
  });

  _exports.default = _default;
});