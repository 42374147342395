define("frontend/validators/password-lower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePasswordLower = validatePasswordLower;

  // @ts-ignore
  function validatePasswordLower() {
    return function (_key, newValue) {
      return newValue !== newValue.toUpperCase() || "Password must contain at least 1 lowercase letter";
    };
  }
});