define("frontend/components/lists/users-list/user-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @compressed}}
    <PaperItem data-test-users-list-item @class="md-2-line" @onClick={{if (can "editContactInfo user" @user) (fn @editUser @user)}} as |controls|>
      <PaperIcon @class="md-avatar-icon">person</PaperIcon>
      <div class="md-list-item-text">
        <h3>{{@user.fullName}}</h3>
        <p>{{@user.username}} ({{@user.permissionsLevel}})</p>
      </div>
    </PaperItem>
  {{else}}
    <PaperItem data-test-users-list-item @onClick={{if (can "editContactInfo user" @user) (fn @editUser @user)}} as |controls|>
      <PaperIcon @class="md-avatar-icon">person</PaperIcon>
      <span class="flex">{{@user.fullName}}</span>
      <span class="flex">{{@user.username}}</span>
      <span class="flex">{{@user.permissionsLevel}}</span>
    </PaperItem>
  {{/if}}
  */
  {
    "id": "EHhvwQrC",
    "block": "{\"symbols\":[\"controls\",\"controls\",\"@user\",\"@editUser\",\"@compressed\"],\"statements\":[[6,[37,2],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"paper-item\",[],[[\"@class\",\"@onClick\"],[\"md-2-line\",[30,[36,2],[[30,[36,1],[\"editContactInfo user\",[32,3]],null],[30,[36,0],[[32,4],[32,3]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@class\"],[\"md-avatar-icon\"]],[[\"default\"],[{\"statements\":[[2,\"person\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[1,[32,3,[\"fullName\"]]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[1,[32,3,[\"username\"]]],[2,\" (\"],[1,[32,3,[\"permissionsLevel\"]]],[2,\")\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"paper-item\",[],[[\"@onClick\"],[[30,[36,2],[[30,[36,1],[\"editContactInfo user\",[32,3]],null],[30,[36,0],[[32,4],[32,3]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@class\"],[\"md-avatar-icon\"]],[[\"default\"],[{\"statements\":[[2,\"person\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex\"],[12],[1,[32,3,[\"fullName\"]]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex\"],[12],[1,[32,3,[\"username\"]]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex\"],[12],[1,[32,3,[\"permissionsLevel\"]]],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"can\",\"if\"]}",
    "moduleName": "frontend/components/lists/users-list/user-list-item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});