define("frontend/components/forms/service-log/body", ["exports", "htt-ui/components/forms/service-log/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _body.default;
    }
  });
});