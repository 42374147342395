define("frontend/components/ui/selects/seconds", ["exports", "htt-ui/components/ui/selects/seconds"], function (_exports, _seconds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _seconds.default;
    }
  });
});