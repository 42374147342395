define("frontend/components/forms/dashboard-element-reports/tdec-sewer-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Inputs::Text
      @changeset={{@changeset}}
      @attribute={{"facility"}}
      @customer={{@customer}}
      @label={{"facility"}}
  />
  <Ui::Inputs::Text
      @changeset={{@changeset}}
      @attribute={{"county"}}
      @customer={{@customer}}
      @label={{"county"}}
  />
  <Ui::Selects::Counter
      @changeset={{@changeset}}
      @attribute={{"meter"}}
      @customer={{@customer}}
      @instLabel={{"installation"}}
      @label={{"meter"}}
  />
  <Ui::Selects::RainCounter
      @changeset={{@changeset}}
      @attribute={{"counter"}}
      @customer={{@customer}}
      @label={{"rain counter"}}
      @instLabel={{"installation"}}
  />
  */
  {
    "id": "CNF65V+7",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\"],[[32,1],\"facility\",[32,2],\"facility\"]],null],[2,\"\\n\"],[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\"],[[32,1],\"county\",[32,2],\"county\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"meter\",[32,2],\"installation\",\"meter\"]],null],[2,\"\\n\"],[8,\"ui/selects/rain-counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\",\"@instLabel\"],[[32,1],\"counter\",[32,2],\"rain counter\",\"installation\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/tdec-sewer-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});