define("frontend/utils/options-variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.textVariable = _exports.pumpGroupVariable = _exports.pumpVariable = _exports.numberVariable = _exports.installationVariable = _exports.digitalOutputVariable = _exports.booleanVariable = void 0;

  function setOption(obj, key, value) {
    obj.set("options.".concat(key), value);
    obj.notifyPropertyChange('options');
  }

  var booleanVariable = function booleanVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value);
      },
      get: function get() {
        return this.get("options.".concat(key));
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.booleanVariable = booleanVariable;

  var digitalOutputVariable = function digitalOutputVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value);
      },
      get: function get() {
        var digitalOutputId = this.get("options.".concat(key));
        return digitalOutputId && this.store.peekRecord('digital-output', digitalOutputId);
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.digitalOutputVariable = digitalOutputVariable;

  var installationVariable = function installationVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value.id);
      },
      get: function get() {
        var installationId = this.get("options.".concat(key));
        return installationId && this.store.peekRecord('installation', installationId);
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.installationVariable = installationVariable;

  var numberVariable = function numberVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value);
      },
      get: function get() {
        return this.get("options.".concat(key));
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.numberVariable = numberVariable;

  var pumpVariable = function pumpVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value.id);
      },
      get: function get() {
        var pumpId = this.get("options.".concat(key));
        return pumpId && this.store.peekRecord('pump', pumpId);
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.pumpVariable = pumpVariable;

  var pumpGroupVariable = function pumpGroupVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value.id);
      },
      get: function get() {
        var pumpGroupId = this.get("options.".concat(key));
        return pumpGroupId && this.store.peekRecord('pump-group', pumpGroupId);
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.pumpGroupVariable = pumpGroupVariable;

  var textVariable = function textVariable(target, key) {
    return {
      set: function set(value) {
        setOption(this, key, value);
      },
      get: function get() {
        return this.get("options.".concat(key));
      },
      enumerable: true,
      configurable: true
    };
  };

  _exports.textVariable = textVariable;
});