define("frontend/components/ui/list-items/telemetry-data-item", ["exports", "htt-ui/components/ui/list-items/telemetry-data-item"], function (_exports, _telemetryDataItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _telemetryDataItem.default;
    }
  });
});