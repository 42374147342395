define("frontend/templates/customer/installations/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QBan3qim",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"customer\"]],\"Sites Map\"]],null],[2,\"\\n\"],[8,\"page-content\",[],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"maps/installations-map\",[],[[\"@customer\",\"@selectedNeighborhood\"],[[32,0,[\"customer\"]],[32,0,[\"selectedNeighborhood\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/customer/installations/map.hbs"
  });

  _exports.default = _default;
});