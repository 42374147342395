define("frontend/models/dashboard-element-reports/pump-starts-and-runtime-report", ["exports", "htt-shared-models/models/dashboard-element-reports/pump-starts-and-runtime-report"], function (_exports, _pumpStartsAndRuntimeReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pumpStartsAndRuntimeReport.default;
    }
  });
});