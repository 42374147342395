define("frontend/utils/reverse-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(array) {
    var len = array.length;

    if (len < 2) {
      return array;
    } else {
      var reversedArr = [];

      for (var i = 0; i < len; i++) {
        reversedArr.push(array[len - 1 - i]);
      }

      return reversedArr;
    }
  }
});