define("frontend/models/dashboard-elements/analog-output", ["exports", "htt-shared-models/models/dashboard-elements/analog-output"], function (_exports, _analogOutput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _analogOutput.default;
    }
  });
});