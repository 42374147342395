define("frontend/validations/dashboard-element-reports/di-report", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true
    }),
    digitalInput: (0, _validators.validatePresence)({
      presence: true
    })
  };
  _exports.default = _default;
});