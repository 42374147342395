define("frontend/validations/modem-type", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ModemTypeValidations = {
    name: (0, _validators.validatePresence)(true),
    manufacturer: (0, _validators.validatePresence)(true),
    transmissionType: (0, _validators.validatePresence)(true)
  };
  var _default = ModemTypeValidations;
  _exports.default = _default;
});