define("frontend/components/cancel-button", ["exports", "htt-ui/components/cancel-button"], function (_exports, _cancelButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cancelButton.default;
    }
  });
});