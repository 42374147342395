define("frontend/validations/analog-calibration", ["exports", "ember-changeset-validations/validators", "frontend/validators/analog-low-val", "frontend/validators/analog-low-cal", "frontend/validators/analog-high-val", "frontend/validators/analog-high-cal"], function (_exports, _validators, _analogLowVal, _analogLowCal, _analogHighVal, _analogHighCal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    sensorOut: (0, _validators.validatePresence)(true),
    lowCal: (0, _analogLowCal.default)(),
    highCal: (0, _analogHighCal.default)(),
    lowVal: (0, _analogLowVal.default)(),
    highVal: (0, _analogHighVal.default)()
  };
  _exports.default = _default;
});