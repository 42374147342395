define("frontend/components/forms/htt-controls/triggers/get-group-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Inputs::Text
    @changeset={{@changeset}}
    @attribute={{"label"}}
    @label={{t "label"}}
    />
    <Ui::Inputs::Text
    @changeset={{@changeset}}
    @attribute={{'key'}}
    @label={{"key"}}
    />
  */
  {
    "id": "Y0moq1+/",
    "block": "{\"symbols\":[\"@changeset\"],\"statements\":[[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"label\",[30,[36,0],[\"label\"],null]]],null],[2,\"\\n  \"],[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"key\",\"key\"]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "frontend/components/forms/htt-controls/triggers/get-group-state.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});