define("frontend/helpers/nested-route-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.nested-route-of
   */

  /**
   * Takes two routes and checks if one is nested under other.
   * @return {Boolean}
   */
  var filterIndex = function filterIndex(str) {
    return String(str).split('.').filter(function (_) {
      return _ !== 'index';
    }).join('.');
  };

  var _default = Ember.Helper.helper(function (params) {
    var parent = filterIndex(params[0]);
    var child = filterIndex(params[1]);
    return child.includes(parent);
  });

  _exports.default = _default;
});