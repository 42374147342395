define("frontend/templates/query-manager-table-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vueetJ7n",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/htt\",[],[[\"@title\"],[\"Query Manager Test Page\"]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex layout-row\"],[12],[2,\"\\n    \"],[8,\"query-manager-table\",[[24,0,\"flex\"]],[[\"@columns\",\"@queryManager\",\"@onRowClicked\"],[[32,0,[\"columns\"]],[32,1],[32,0,[\"rowClicked\"]]]],null],[2,\"\\n    \"],[10,\"div\"],[14,5,\"width: 300px; background: gray;\"],[12],[2,\"test filter\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/query-manager-table-test.hbs"
  });

  _exports.default = _default;
});