define("frontend/validators/address-street-or-lat-lng", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateAddressStreetOrLatLng;

  function validateAddressStreetOrLatLng(options) {
    return function (key, value, _oldValue, changes, content) {
      console.log("test");
      var hasStreet1 = changes['street_1'] || changes['street_1'] === undefined && content['street_1'];
      var hasLat = changes['lat'] || changes['lat'] === undefined && content['lat'];

      if (!hasStreet1 && !hasLat) {
        return "Must have steet address or lat and lng";
      } else {
        return true;
      }
    };
  }
});