define("frontend/components/rtu-configs-filters", ["exports", "htt-ui/components/rtu-configs-filters"], function (_exports, _rtuConfigsFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rtuConfigsFilters.default;
    }
  });
});