define("frontend/templates/customer/installations/details/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kR0l675i",
    "block": "{\"symbols\":[\"option\",\"toolbar\"],\"statements\":[[10,\"div\"],[14,5,\"overflow: auto;\"],[12],[2,\"\\n  \"],[8,\"paper-toolbar\",[],[[\"@primary\",\"@class\"],[true,[30,[36,0],[[32,0,[\"globals\",\"isSmall\"]],\"md-menu-toolbar\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"tools\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,0,[\"goBackToInstDetails\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"keyboard_backspace\"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Create a new installation\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"layout-row\"],[12],[2,\"\\n    \"],[8,\"paper-select\",[[24,0,\"flex md-padding\"],[24,\"title\",\"Select timezone for installation\"]],[[\"@label\",\"@selected\",\"@defaultValue\",\"@onChange\",\"@required\",\"@options\"],[\"Select RTU generation...\",[32,0,[\"selectedRtuGeneration\"]],\"Gen 2\",[32,0,[\"setRtuGeneration\"]],true,[32,0,[\"rtuGenerations\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"key\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"installation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"forms/installation-form\",[],[[\"@installation\",\"@onCancel\",\"@afterSave\"],[[32,0,[\"installation\"]],[32,0,[\"goBackToInstDetails\"]],[32,0,[\"goBackToInstDetails\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"strong\"],[14,0,\"layout-row layout-align-center\"],[12],[2,\"\\n      Please choose an RTU generation to proceed...\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"if\"]}",
    "moduleName": "frontend/templates/customer/installations/details/create.hbs"
  });

  _exports.default = _default;
});