define("frontend/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.capitalize
   */

  /**
   * Capitalizes string.
   * @method capitalize
   * @return number
   */
  var _default = Ember.Helper.helper(function (params) {
    return params[0].capitalize();
  });

  _exports.default = _default;
});