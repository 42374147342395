define("frontend/templates/customer/installations/details/inst/logs/technical-support-log/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o6rrdV5c",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"Edit Log\",[30,[36,0],[\"customer.installations.details.inst.logs.technical-support-log\"],null]]],null],[2,\"\\n\"],[8,\"forms/technical-support-log\",[],[[\"@log\",\"@onSave\",\"@onCancel\"],[[32,1],[30,[36,0],[\"customer.installations.details.inst.logs.technical-support-log\"],null],[30,[36,0],[\"customer.installations.details.inst.logs.technical-support-log\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"replace-with\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/logs/technical-support-log/edit.hbs"
  });

  _exports.default = _default;
});