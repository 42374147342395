define("frontend/validations/analog-identity", ["exports", "ember-changeset-validations/validators", "frontend/validators/io-location"], function (_exports, _validators, _ioLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.units = _exports.number = _exports.name = void 0;
  // @ts-ignore
  var name = {
    name: (0, _validators.validatePresence)(true)
  };
  _exports.name = name;
  var number = {
    number: [(0, _ioLocation.default)({
      scope: "analogs"
    }), (0, _validators.validatePresence)(true)]
  };
  _exports.number = number;
  var units = {
    units: (0, _validators.validatePresence)(true)
  };
  _exports.units = units;

  var _default = Object.assign({}, name, number, units);

  _exports.default = _default;
});