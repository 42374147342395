define("frontend/models/dashboard-element-reports/wtp-turbidity-report", ["exports", "htt-shared-models/models/dashboard-element-reports/wtp-turbidity-report"], function (_exports, _wtpTurbidityReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wtpTurbidityReport.default;
    }
  });
});