define("frontend/templates/customer/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "viSP2BUt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/base-appbar\",[],[[],[]],null],[2,\"\\n\"],[8,\"page-content\",[[24,0,\"layout-align-center-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-progress-circular\",[],[[\"@primary\",\"@diameter\"],[true,96]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/customer/loading.hbs"
  });

  _exports.default = _default;
});