define("frontend/models/pump-correlated-reading", ["exports", "htt-shared-models/models/pump-correlated-reading"], function (_exports, _pumpCorrelatedReading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pumpCorrelatedReading.default;
    }
  });
});