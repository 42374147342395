define("frontend/components/paper-modals/report-forms/gas-odor--download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperDialog @fullscreen={{globals.isMobile}}>
   <PaperToolbar>
      <PaperToolbarHeader @title={{promptMessage}} @onClose={{action "cancel"}} />
    </PaperToolbar>
    <PaperDialogContent>
    <div class="layout-row">
      <Ui::Inputs::Datepicker @class="flex-50" @date={{startDate}} @maxDate={{endDate}} @label={{t "startDate"}} @onChange={{action "setStartDate"}} />
      <Ui::Inputs::Datepicker @class="flex-50" @date={{endDate}} @minDate={{startDate}} @label={{t "endDate"}} @onChange={{action "setEndDate"}} />
    </div>
  </PaperDialogContent>
  <PaperDialogActions @class="layout-row">
    <span class="flex"></span>
      <PaperButton @primary={{true}} @onClick={{action "cancel"}} @disabled={{pendingSave}}>{{t "cancel"}}</PaperButton>
      <PaperButton @primary={{true}} @onClick={{action "download"}} @disabled={{or datesNotSet pendingSave}}>{{t "download"}}</PaperButton>
  </PaperDialogActions>
  </PaperDialog>
  */
  {
    "id": "BMV9g3zb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-dialog\",[],[[\"@fullscreen\"],[[34,0,[\"isMobile\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n \"],[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-toolbar-header\",[],[[\"@title\",\"@onClose\"],[[34,1],[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n    \"],[8,\"ui/inputs/datepicker\",[],[[\"@class\",\"@date\",\"@maxDate\",\"@label\",\"@onChange\"],[\"flex-50\",[34,3],[34,4],[30,[36,5],[\"startDate\"],null],[30,[36,2],[[32,0],\"setStartDate\"],null]]],null],[2,\"\\n    \"],[8,\"ui/inputs/datepicker\",[],[[\"@class\",\"@date\",\"@minDate\",\"@label\",\"@onChange\"],[\"flex-50\",[34,4],[34,3],[30,[36,5],[\"endDate\"],null],[30,[36,2],[[32,0],\"setEndDate\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"paper-dialog-actions\",[],[[\"@class\"],[\"layout-row\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\",\"@disabled\"],[true,[30,[36,2],[[32,0],\"cancel\"],null],[34,6]]],[[\"default\"],[{\"statements\":[[1,[30,[36,5],[\"cancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\",\"@disabled\"],[true,[30,[36,2],[[32,0],\"download\"],null],[30,[36,8],[[35,7],[35,6]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,5],[\"download\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"globals\",\"promptMessage\",\"action\",\"startDate\",\"endDate\",\"t\",\"pendingSave\",\"datesNotSet\",\"or\"]}",
    "moduleName": "frontend/components/paper-modals/report-forms/gas-odor--download.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    paperModal: Ember.inject.service(),
    gas: Ember.computed.alias("modalData.gas"),
    odor: Ember.computed.alias("modalData.odor"),
    altGas: Ember.computed.alias("modalData.altGas"),
    timezone: Ember.computed.alias("gas.installation.timezone"),
    startTimestamp: null,
    endTimestamp: null,
    startDate: null,
    endDate: null,
    promptMessage: Ember.computed(function () {
      return this.intl.t("pleaseSelectDates");
    }),
    datesNotSet: Ember.computed("startDate", "endDate", function () {
      return Ember.isNone(this.startDate) || Ember.isNone(this.endDate);
    }),
    actions: {
      download: function download() {
        var _this = this;

        var odor = this.odor;
        var altGas = this.altGas;
        this.gas.downloadGasOdorReadings(this.startDate, this.endDate, odor, altGas).then(function () {
          _this.paperModal.closeModal();
        });
      },
      cancel: function cancel() {
        this.paperModal.closeModal();
      },
      setStartDate: function setStartDate(startDate) {
        if (Ember.isPresent(this.endDate) && startDate.moment > this.endDate) {
          this.set("startDate", this.endDate);
        } else {
          this.set("startDate", startDate.moment);
        }
      },
      setEndDate: function setEndDate(endDate) {
        if (Ember.isPresent(this.endDate) && endDate.moment < this.startDate) {
          this.set("endDate", this.startDate);
        } else {
          this.set("endDate", endDate.moment);
        }
      }
    }
  }));

  _exports.default = _default;
});