define("frontend/components/alarms-filters", ["exports", "htt-ui/components/alarms-filters"], function (_exports, _alarmsFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _alarmsFilters.default;
    }
  });
});