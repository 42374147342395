define("frontend/components/forms/dashboard-element-reports/valve-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Valve
    @changeset={{@changeset}}
    @attribute={{"valve"}}
    @customer={{@customer}}
    @label={{"Valve"}}
    @instLabel={{"installation"}}
    @valLabel={{"Valve"}}
  />
  */
  {
    "id": "0xlXG16z",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/valve\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\",\"@instLabel\",\"@valLabel\"],[[32,1],\"valve\",[32,2],\"Valve\",\"installation\",\"Valve\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/valve-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});