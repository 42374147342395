define("frontend/helpers/get-latlng", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.get-latlng
   */

  /**
   * Used to get the latitude and longitude of given model object
   * @method get-latlng
   * @return array of latitude and longitude
   */
  var _default = Ember.Helper.helper(function (params) {
    var obj = params[0];

    if (Ember.isNone(obj) || Ember.isNone(obj.get("lat")) || Ember.isNone(obj.get("lng"))) {
      return null;
    } else {
      return [obj.get("lat"), obj.get("lng")];
    }
  });

  _exports.default = _default;
});