define("frontend/helpers/camelize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.camelize
   */

  /**
   * camelizes string.
   * @method camelize
   * @return Merged string of given parameters
   */
  var _default = Ember.Helper.helper(function (params)
  /*, hash*/
  {
    return params[0].camelize();
  });

  _exports.default = _default;
});