define("frontend/components/password-inputs", ["exports", "htt-ui/components/password-inputs"], function (_exports, _passwordInputs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _passwordInputs.default;
    }
  });
});