define("frontend/validations/rain-counter", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RainCounterValidations = void 0;
  // @ts-ignore
  var RainCounterValidations = {
    number: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      allowString: true,
      gte: 1,
      lte: 4
    })],
    startOfDay: (0, _validators.validateNumber)({
      allowString: false,
      gte: 0,
      lte: 1439
    })
  };
  _exports.RainCounterValidations = RainCounterValidations;
});