define("frontend/validators/analog-low-val", ["exports", "frontend/utils/analog-sensor-types"], function (_exports, _analogSensorTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return function (key, newValue, _oldValue, changes, content) {
      var lowVal = newValue;
      var sensorOut = Ember.get(changes, 'sensorOut') || Ember.get(content, 'sensorOut');
      var highVal = Ember.get(changes, 'highVal') || Ember.get(content, 'highVal'); //no validation if sensorOut is of types

      if (_analogSensorTypes.default.isNotScaled(sensorOut)) return true; //error if lowVal is null

      if (Ember.isNone(highVal)) return "".concat(key, " must be set");
      highVal = parseInt(highVal);
      lowVal = parseInt(lowVal);
      if (sensorOut === "current" && (lowVal < 4 || lowVal > 20)) return "".concat(key, " must be between 4-20 ma");
      if (sensorOut === "voltage" && (lowVal < 0 || lowVal > 5)) return "".concat(key, " must be between 0-5 V");
      if (lowVal >= highVal) return "".concat(key, " must be below High Val");
      return true;
    };
  }
});