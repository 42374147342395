define("frontend/templates/customer/installations/details/inst/logs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0g2rn89p",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,0,[\"globals\",\"isSmall\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"installation-mobile-header\",[],[[\"@installation\"],[[32,0,[\"installation\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@filterComponent\",\"@listItemComponent\",\"@createComponent\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"onLogClick\"]],[32,1],[32,0,[\"columns\"]],\"logs-filters\",\"ui/list-items/log\",[30,[36,1],[\"buttons/logs-speed-dial\"],[[\"handleServiceLog\",\"handleTechnicalSupportLog\"],[[32,0,[\"handleServiceLog\"]],[32,0,[\"handleTechnicalSupportLog\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"if\",\"component\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/logs/index.hbs"
  });

  _exports.default = _default;
});