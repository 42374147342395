define("frontend/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Helper from '@ember/component/helper';

  /**
   * @module Helper
   */

  /**
   * @class Helper.is-even
   */

  /**
   * Takes installation inAlarm property and returns an appropriate alarm class.
   * @method collector-leaflet-icon
   * @return L.icon object
   */
  var _default = Ember.Helper.helper(function (params) {
    return Ember.String.htmlSafe(params[0]);
  });

  _exports.default = _default;
});