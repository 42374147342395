define("frontend/templates/customer/installations/details/inst/rtu-configs/rtu-config-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OMtUhEX/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/nested\",[],[[\"@onBack\",\"@title\"],[[30,[36,0],[\"customer.installations.details.inst.rtuConfigs\"],null],\"RTU Configs\"]],null],[2,\"\\n\\n\"],[8,\"paper-content\",[[24,0,\"flex-grow layout-column md-padding\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Type\"],[13],[2,\"\\n    \"],[1,[32,0,[\"model\",\"type\"]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"model\",\"desiredAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h4\"],[12],[2,\"Desired\"],[13],[2,\"\\n        \"],[1,[32,0,[\"model\",\"formattedDesiredTime\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"reportedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h4\"],[12],[2,\"Reported\"],[13],[2,\"\\n        \"],[1,[32,0,[\"model\",\"formattedReportedTime\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Config\"],[13],[2,\"\\n    \"],[10,\"pre\"],[12],[10,\"code\"],[12],[1,[32,0,[\"model\",\"configJsonPretty\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"replace-with\",\"if\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/rtu-configs/rtu-config-detail.hbs"
  });

  _exports.default = _default;
});