define("frontend/models/dashboard-elements/text-analog", ["exports", "htt-shared-models/models/dashboard-elements/text-analog"], function (_exports, _textAnalog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _textAnalog.default;
    }
  });
});