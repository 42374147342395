define("frontend/helpers/seconds-to-hours", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.secondsToHours = secondsToHours;
  _exports.default = void 0;

  function secondsToHours(positional) {
    var seconds = positional[0];
    return seconds ? (0, _lodashEs.round)(seconds / 60 / 60, 2) : seconds;
  }

  var _default = Ember.Helper.helper(secondsToHours);

  _exports.default = _default;
});