define("frontend/templates/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cMtaVOcO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/htt\",[],[[\"@title\"],[[30,[36,0],[\"user.many\"],null]]],null],[2,\"\\n\"],[8,\"ui/search-input\",[[24,\"placeholder\",\"Filter by text\"]],[[\"@onChange\",\"@value\"],[[32,0,[\"updateSearchString\"]],[32,0,[\"searchString\"]]]],null],[2,\"\\n\"],[8,\"page-content\",[],[[\"@padding\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"lists/users-list\",[],[[\"@users\",\"@editUser\"],[[32,0,[\"queryManager\",\"data\"]],[32,0,[\"editUser\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "frontend/templates/users.hbs"
  });

  _exports.default = _default;
});