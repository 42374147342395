define("frontend/components/forms/htt-controls/triggers/digital-input-status-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Inputs::Text
    @changeset={{@changeset}}
    @attribute={{"label"}}
    @label={{t "label"}}
    />
  <Ui::Selects::DigitalInput
    @changeset={{@changeset}}
    @attribute={{'digitalInput'}}
    @customer={{@customer}}
    />
  */
  {
    "id": "uFpRkRoT",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"label\",[30,[36,0],[\"label\"],null]]],null],[2,\"\\n\"],[8,\"ui/selects/digital-input\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"digitalInput\",[32,2]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "frontend/components/forms/htt-controls/triggers/digital-input-status-open.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});