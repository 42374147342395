define("frontend/templates/distributor/statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vqLFYxvT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"content-header\"],[12],[10,\"h1\"],[12],[1,[30,[36,0],[\"statistics\"],null]],[13],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"content\"],[12],[2,\"\\n\\n\"],[1,[32,0,[\"gpgPumpStatsReadings\",\"length\"]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "frontend/templates/distributor/statistics.hbs"
  });

  _exports.default = _default;
});