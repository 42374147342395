define("frontend/models/htt-controls/actions/digital-input-write", ["exports", "htt-shared-models/models/htt-controls/actions/digital-input-write"], function (_exports, _digitalInputWrite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _digitalInputWrite.default;
    }
  });
});