define("frontend/components/timezone-select", ["exports", "htt-ui/components/timezone-select"], function (_exports, _timezoneSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _timezoneSelect.default;
    }
  });
});