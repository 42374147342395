define("frontend/helpers/absolute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.absolute
   */

  /**
   * Takes numbers and outputs absolute value.
   * @method absolute
   * @return number
   */
  var _default = Ember.Helper.helper(function (params) {
    return Math.abs(params[0]);
  });

  _exports.default = _default;
});