define("frontend/instance-initializers/check-screen-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @module Initializer
   */

  /**
   * Preloads the screen size into globals service.
   * @class Initializer.check-size
   */
  function checkMediaQueryProperties(contants, globals) {
    var mediaQuery;
    mediaQuery = contants.MEDIA["gt-sm"];
    mediaQuery = window.matchMedia(mediaQuery).matches;
    globals.set("isSmall", !mediaQuery); //globals.set("defaultSideNavLockedOpen", mediaQuery);

    mediaQuery = contants.MEDIA["gt-xs"];
    mediaQuery = window.matchMedia(mediaQuery).matches;
    globals.set("isExtraSmall", !mediaQuery);
    mediaQuery = contants.MEDIA["gt-sm"];
    mediaQuery = window.matchMedia(mediaQuery).matches;
    globals.set("isMedium", mediaQuery);
    mediaQuery = contants.MEDIA["gt-md"];
    mediaQuery = window.matchMedia(mediaQuery).matches;
    globals.set("isLarge", mediaQuery);
  }

  function initialize(application) {
    var globalsService = application.lookup("service:globals");
    var contantsService = application.lookup("service:constants");
    var resizeService = application.lookup("service:resize"); //set initial width

    globalsService.set("screenWidth", window.innerWidth);
    checkMediaQueryProperties(contantsService, globalsService);
    resizeService.on('didResize', function () {
      //reset width on screen resize
      checkMediaQueryProperties(contantsService, globalsService);
    });
  }

  var _default = {
    name: "checkScreenSize",
    initialize: initialize
  };
  _exports.default = _default;
});