define("frontend/models/installation-config", ["exports", "htt-shared-models/models/installation-config"], function (_exports, _installationConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _installationConfig.default;
    }
  });
});