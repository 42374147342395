define("frontend/validations/contact", ["exports", "ember-changeset-validations/validators", "frontend/validators/password-capital", "frontend/validators/password-lower", "frontend/validators/password-number"], function (_exports, _validators, _passwordCapital, _passwordLower, _passwordNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NewContactValidations = _exports.PasswordValidations = _exports.AlarmSoundValidations = _exports.PermissionsLevelValidations = _exports.NameValidations = _exports.ContactValidations = void 0;
  // @ts-ignore
  var ContactValidations = {
    username: (0, _validators.validatePresence)(true),
    permissionsLevel: (0, _validators.validatePresence)(true),
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true)
  };
  _exports.ContactValidations = ContactValidations;
  var NameValidations = {
    username: (0, _validators.validatePresence)(true),
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true)
  };
  _exports.NameValidations = NameValidations;
  var PermissionsLevelValidations = {
    permissionsLevel: (0, _validators.validatePresence)(true)
  };
  _exports.PermissionsLevelValidations = PermissionsLevelValidations;
  var AlarmSoundValidations = {
    alarmSound: (0, _validators.validatePresence)(true)
  };
  _exports.AlarmSoundValidations = AlarmSoundValidations;
  var PasswordValidations = {
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8
    }), (0, _passwordCapital.validatePasswordCapital)(), (0, _passwordLower.validatePasswordLower)(), (0, _passwordNumber.validatePasswordNumber)()],
    passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({
      on: 'password'
    })]
  };
  _exports.PasswordValidations = PasswordValidations;
  var NewContactValidations = Object.assign({}, NameValidations, PermissionsLevelValidations, AlarmSoundValidations, PasswordValidations);
  _exports.NewContactValidations = NewContactValidations;

  var _default = Ember.assign({}, ContactValidations
  /* , PasswordValidations */
  );

  _exports.default = _default;
});