define("frontend/components/table/cells/test-cell", ["exports", "query-manager/components/table/cells/test-cell"], function (_exports, _testCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _testCell.default;
    }
  });
});