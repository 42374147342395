define("frontend/validations/dashboard-display", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CustomerValidations = void 0;
  // ---------- validations ------------
  var CustomerValidations = {
    label: (0, _validators.validatePresence)(true)
  };
  _exports.CustomerValidations = CustomerValidations;
});