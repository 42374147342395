define("frontend/instance-initializers/register-component", ["exports", "ember-leaflet-marker-cluster/instance-initializers/register-component"], function (_exports, _registerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _registerComponent.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _registerComponent.initialize;
    }
  });
});