define("frontend/templates/customer/installations/details/inst/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xH5vTOto",
    "block": "{\"symbols\":[\"tabs\",\"toolbar\"],\"statements\":[[2,\"  \"],[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"tools\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[32,0,[\"transitionUp\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"arrow_back\"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[10,\"h2\"],[12],[1,[32,0,[\"installation\",\"name\"]]],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[8,\"paper-tabs\",[],[[\"@primary\",\"@stretch\",\"@selected\"],[true,true,0]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"tab\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          Alarms\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1,[\"tab\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          Logs\\n      \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"lists/installation-routes\",[],[[\"@installation\",\"@divider\"],[[32,0,[\"installation\"]],true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/index.hbs"
  });

  _exports.default = _default;
});