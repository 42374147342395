define("frontend/components/side-nav-menu/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem
    class={{concat "menu-item" (if @active " active") (if @expanded " expanded") (if @parent " parent")}}
    @onClick={{@onClick}}
    ...attributes
  >
    {{yield}}
    {{#if @canExpand}}
      <PaperIcon class="expand-icon">
        {{if @expanded "expand_less" "expand_more"}}
      </PaperIcon>
    {{/if}}
  </PaperItem>
  */
  {
    "id": "MCWbBbJv",
    "block": "{\"symbols\":[\"@expanded\",\"@parent\",\"@active\",\"@onClick\",\"&attrs\",\"&default\",\"@canExpand\"],\"statements\":[[8,\"paper-item\",[[16,0,[30,[36,1],[\"menu-item\",[30,[36,0],[[32,3],\" active\"],null],[30,[36,0],[[32,1],\" expanded\"],null],[30,[36,0],[[32,2],\" parent\"],null]],null]],[17,5]],[[\"@onClick\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,6,null],[2,\"\\n\"],[6,[37,0],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-icon\",[[24,0,\"expand-icon\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[32,1],\"expand_less\",\"expand_more\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "moduleName": "frontend/components/side-nav-menu/menu-item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});