define("frontend/components/query-manager-table", ["exports", "query-manager/components/query-manager-table"], function (_exports, _queryManagerTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _queryManagerTable.default;
    }
  });
});