define("frontend/components/side-nav-menu/submenu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem @onClick={{@onClick}} class={{concat "submenu-item" (if @active " active" )}} ...attributes>
    <span class="flex-10"></span>
    {{yield}}
  </PaperItem>
  */
  {
    "id": "UZZ87YL6",
    "block": "{\"symbols\":[\"@onClick\",\"@active\",\"&attrs\",\"&default\"],\"statements\":[[8,\"paper-item\",[[16,0,[30,[36,1],[\"submenu-item\",[30,[36,0],[[32,2],\" active\"],null]],null]],[17,3]],[[\"@onClick\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex-10\"],[12],[13],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "moduleName": "frontend/components/side-nav-menu/submenu-item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});