define("frontend/templates/hardware-types/code-versions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4XyOkV7/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex layout-column\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"main\"],[12],[2,\"\\n  \"],[8,\"app-bars/hardware-types\",[],[[\"@currentRoute\"],[\"hardware-types.code-versions.index\"]],null],[2,\"\\n  \"],[8,\"query-manager-view\",[],[[\"@listView\",\"@queryManager\",\"@columns\",\"@onCreate\",\"@listItemComponent\",\"@onItemClick\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"model\",\"queryManager\"]],[32,0,[\"model\",\"columns\"]],[30,[36,0],[\"hardware-types.code-versions.create\"],null],[30,[36,1],[\"ui/list-items/code-version-display\"],null],[32,0,[\"onItemClick\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"transition-to\",\"component\"]}",
    "moduleName": "frontend/templates/hardware-types/code-versions/index.hbs"
  });

  _exports.default = _default;
});