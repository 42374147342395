define("frontend/utils/offset-to-utc", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.offsetToUTC = offsetToUTC;

  function offsetToUTC(date) {
    return date && _moment.default.tz(date.toString(), 'UTC').toISOString().slice(0, -1);
  }
});