define("frontend/components/forms/dashboard-element-reports/ap-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Analog
    @changeset={{@changeset}}
    @attribute={{"analog"}}
    @customer={{@customer}}
  />
  <Ui::Selects::Pump
    @changeset={{@changeset}}
    @attribute={{"pump_1"}}
    @customer={{@customer}}
  />
  <Ui::Selects::Pump
    @changeset={{@changeset}}
    @attribute={{"pump_2"}}
    @customer={{@customer}}
  />
  <Ui::Selects::Pump
    @changeset={{@changeset}}
    @attribute={{"pump_3"}}
    @customer={{@customer}}
  />
  <Ui::Selects::Pump
    @changeset={{@changeset}}
    @attribute={{"pump_4"}}
    @customer={{@customer}}
  />
  <Ui::Selects::DigitalOutput
    @changeset={{@changeset}}
    @attribute={{"do_1"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @diLabel={{"Digital Output"}}
  />
  <Ui::Selects::DigitalOutput
    @changeset={{@changeset}}
    @attribute={{"do_2"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @diLabel={{"Digital Output"}}
  />
  <Ui::Selects::DigitalOutput
    @changeset={{@changeset}}
    @attribute={{"do_3"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @diLabel={{"Digital Output"}}
  />
  <Ui::Selects::DigitalOutput
    @changeset={{@changeset}}
    @attribute={{"do_4"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @diLabel={{"Digital Output"}}
  />
  
  */
  {
    "id": "CAbm/LUB",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/analog\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"analog\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/pump\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"pump_1\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/pump\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"pump_2\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/pump\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"pump_3\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/pump\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"pump_4\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/digital-output\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@diLabel\"],[[32,1],\"do_1\",[32,2],\"installation\",\"Digital Output\"]],null],[2,\"\\n\"],[8,\"ui/selects/digital-output\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@diLabel\"],[[32,1],\"do_2\",[32,2],\"installation\",\"Digital Output\"]],null],[2,\"\\n\"],[8,\"ui/selects/digital-output\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@diLabel\"],[[32,1],\"do_3\",[32,2],\"installation\",\"Digital Output\"]],null],[2,\"\\n\"],[8,\"ui/selects/digital-output\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@diLabel\"],[[32,1],\"do_4\",[32,2],\"installation\",\"Digital Output\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/ap-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});