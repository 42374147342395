define("frontend/components/forms/htt-controls/triggers/analog-exceed-threshold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Inputs::Text
    @changeset={{@changeset}}
    @attribute={{"label"}}
    @label={{t "label"}}
    />
  <Ui::Selects::Analog
    @changeset={{@changeset}}
    @attribute={{'analog'}}
    @customer={{@customer}}
    />
  <Ui::Selects::AnalogThreshold
    @changeset={{@changeset}}
    @attribute={{'threshold'}}
    @analog={{@changeset.analog}}
    />
  
  */
  {
    "id": "XBUdk0iL",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"label\",[30,[36,0],[\"label\"],null]]],null],[2,\"\\n\"],[8,\"ui/selects/analog\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"analog\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/analog-threshold\",[],[[\"@changeset\",\"@attribute\",\"@analog\"],[[32,1],\"threshold\",[32,1,[\"analog\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "frontend/components/forms/htt-controls/triggers/analog-exceed-threshold.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});