define("frontend/templates/hardware-types/modem-types/modem-type/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uXNT5APQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"Edit Modem Type\",[32,0,[\"cancel\"]]]],null],[2,\"\\n\"],[8,\"paper-form\",[[24,0,\"md-padding layout-column flex\"]],[[\"@onSubmit\"],[[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex form-body\"],[12],[2,\"\\n      \"],[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],\"name\",\"name\"]],null],[2,\"\\n      \"],[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],\"manufacturer\",\"manufacturer\"]],null],[2,\"\\n      \"],[8,\"ui/inputs/checkbox\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],\"ipModule\",\"ip\\n        module\"]],null],[2,\"\\n      \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@attribute\",\"@options\",\"@label\"],[[32,0,[\"changeset\"]],\"transmissionType\",[32,0,[\"options\"]],\"transmission type\"]],null],[2,\"\\n      \"],[8,\"ui/inputs/text-area\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],\"description\",\"description\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"form-save-cancel-btns\",[],[[\"@onCancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/hardware-types/modem-types/modem-type/edit.hbs"
  });

  _exports.default = _default;
});