define("frontend/validations/counter-general", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    startOfDay: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)(true)],
    dailyMin: [(0, _validators.validateNumber)({
      allowString: true,
      allowBlank: true,
      integer: true,
      min: 0
    })],
    dailyMax: [(0, _validators.validateNumber)({
      allowString: true,
      allowBlank: true,
      integer: true,
      min: 0
    })]
  };
  _exports.default = _default;
});