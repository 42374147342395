define("frontend/components/radio-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "input",
    attributeBindings: ['type', 'checked', 'value', 'name', 'disabled'],
    type: "radio",
    checked: null,
    value: null,
    name: null,
    disabled: null
  });

  _exports.default = _default;
});