define("frontend/validations/analog-output-identity", ["exports", "ember-changeset-validations/validators", "frontend/validators/analog-output-location"], function (_exports, _validators, _analogOutputLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    name: (0, _validators.validatePresence)(true),
    number: [(0, _analogOutputLocation.default)(), (0, _validators.validatePresence)(true)],
    units: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});