define("frontend/templates/customer/logs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QT1/sepg",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"customer\"]],\"Logs\"]],null],[2,\"\\n\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@filterComponent\",\"@listItemComponent\",\"@createComponent\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"onLogClick\"]],[32,1],[32,0,[\"columns\"]],\"logs-filters\",\"ui/list-items/log\",[30,[36,0],[\"buttons/logs-speed-dial\"],[[\"handleServiceLog\",\"handleTechnicalSupportLog\"],[[32,0,[\"handleServiceLog\"]],[32,0,[\"handleTechnicalSupportLog\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "frontend/templates/customer/logs/index.hbs"
  });

  _exports.default = _default;
});