define("frontend/validations/dashboard-elements/multi-digital-input", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    not_in_alarm_message: (0, _validators.validatePresence)({
      presence: true
    }),
    in_alarm_message: (0, _validators.validatePresence)({
      presence: true
    }),
    digital_input_1: (0, _validators.validatePresence)({
      presence: true
    })
  };
  _exports.default = _default;
});