define("frontend/validators/counter-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateCounterLocation;

  function validateCounterLocation() {
    return function (_key, newValue, _oldValue, _changes, content) {
      // get number and ensure it's an int
      var number = newValue;
      if (number) number = parseInt(number);
      var installation = content.get("installation");
      var CounterSameException = new Error("Counter number must be unique");

      try {
        installation.get("counters").forEach(function (counter) {
          // get number and ensure int value 
          var aNum = parseInt(counter.get("number"));

          if (counter.get("id") !== content.get("id") && number == aNum) {
            // numbers are same 
            throw CounterSameException;
          }
        });
      } catch (e) {
        if (e !== CounterSameException) throw e;
        return "Number must be unique";
      }

      return true;
    };
  }
});