define("frontend/models/htt-controls/action", ["exports", "htt-shared-models/models/htt-controls/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _action.default;
    }
  });
});