define("frontend/templates/customer/gpg-neighborhood/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iys8U3ws",
    "block": "{\"symbols\":[],\"statements\":[[8,\"page-content\",[],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"maps/gpg-neighborhood-map\",[],[[\"@gpgNeighborhood\"],[[32,0,[\"gpgNeighborhood\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/customer/gpg-neighborhood/map.hbs"
  });

  _exports.default = _default;
});