define("frontend/templates/customer/alarm-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1IyoJjth",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"customer\"]],[30,[36,2],[[30,[36,1],[[30,[36,0],[\"alarmSettings\"],null],\" - \",[32,0,[\"alarmGroup\",\"name\"]]],null]],null]]],null],[2,\"\\n\"],[8,\"page-content\",[],[[\"@padding\",\"@class\"],[true,\"position-static\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"lists/alarm-group-contact-links\",[],[[\"@editAgcl\",\"@deleteAgcl\",\"@toggleSuspendAgcl\",\"@alarmGroupContactLinks\"],[[32,0,[\"edit\"]],[32,0,[\"delete\"]],[32,0,[\"toggleSuspend\"]],[32,0,[\"model\",\"alarmGroupContactLinks\"]]]],null],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[\"create alarm-group-contact-link\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-button\",[],[[\"@raised\",\"@fab\",\"@class\",\"@onClick\"],[true,true,\"green-btn bottom-right-btn absolute-position\",[32,0,[\"create\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        add\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"titleize\",\"can\",\"if\"]}",
    "moduleName": "frontend/templates/customer/alarm-group.hbs"
  });

  _exports.default = _default;
});