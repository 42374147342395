define("frontend/validations/address", ["exports", "ember-changeset-validations/validators", "frontend/validators/address-street-or-lat-lng"], function (_exports, _validators, _addressStreetOrLatLng) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    street_1: (0, _addressStreetOrLatLng.default)(true),
    city: (0, _validators.validatePresence)({
      presence: true,
      on: 'street_1'
    }),
    region: (0, _validators.validatePresence)({
      presence: true,
      on: 'street_1'
    }),
    postalCode: (0, _validators.validatePresence)({
      presence: true,
      on: 'street_1'
    }),
    lat: [(0, _addressStreetOrLatLng.default)(true), (0, _validators.validatePresence)({
      presence: true,
      on: 'lng'
    }), (0, _validators.validateNumber)({
      allowString: true,
      allowBlank: true,
      gte: -90,
      lte: 90
    })],
    lng: [(0, _validators.validatePresence)({
      presence: true,
      on: 'lat'
    }), (0, _validators.validateNumber)({
      allowString: true,
      allowBlank: true,
      gte: -180,
      lte: 180
    })]
  };
  _exports.default = _default;
});