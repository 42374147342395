define("frontend/templates/customer/installations/details/inst/settings/io/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tiwQ4VWe",
    "block": "{\"symbols\":[\"tabs\",\"tab\"],\"statements\":[[6,[37,0],[[32,0,[\"globals\",\"isMobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"paper-toolbar\",[],[[\"@primary\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"paper-tabs\",[],[[\"@class\",\"@stretch\",\"@selected\",\"@primary\",\"@onChange\"],[\"\",[32,0,[\"globals\",\"isMobile\"]],0,[32,0,[\"globals\",\"isMobile\"]],[30,[36,1],[[32,0,[\"noop\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"tabsConfig\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"tab\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"page-content\",[],[[\"@class\"],[\"layout-align-center-center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-progress-circular\",[],[[\"@primary\",\"@diameter\"],[true,96]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"-track-array\",\"each\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/settings/io/loading.hbs"
  });

  _exports.default = _default;
});