define("frontend/validators/io-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateIOLocation;

  function validateIOLocation() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var scope = options["scope"];
    return function (key, newValue, _oldValue, changes, content) {
      // get number and ensure it's an int
      var number = newValue;
      if (number) number = parseInt(number); // get plc and make sure true or false value
      // ERROR: If plc value is false, we don't register the change

      var plc = changes.hasOwnProperty('plc') ? Ember.get(changes, 'plc') : Ember.get(content, 'plc');
      if (Ember.isNone(plc)) plc = false; // get slot and make sure number or null

      var slot = changes.hasOwnProperty('slot') ? Ember.get(changes, 'slot') : Ember.get(content, 'slot');
      slot = Ember.isNone(slot) ? null : parseInt(slot);
      var installation = content.get("installation");
      var DigitalInputSameException = {};

      try {
        installation.get(scope).forEach(function (io) {
          // get plc and ensure true or false value
          var ioPlc = io.get("plc");
          ioPlc = Ember.isNone(ioPlc) ? false : ioPlc; //get slot and ensure int or null value

          var ioSlot = io.get("slot");
          ioSlot = Ember.isNone(ioSlot) ? null : parseInt(ioSlot); // get number and ensure int value 

          var ioNum = parseInt(io.get(key));

          if (io.get("id") !== content.get("id") && number == ioNum && plc == ioPlc && slot == ioSlot) {
            // numbers are same and plc is false or numbers, plc, and slot are the same
            throw DigitalInputSameException;
          }
        });
      } catch (e) {
        if (e !== DigitalInputSameException) throw e;
        return "Number, PLC, Slot must be unique";
      }

      return true;
    };
  }
});