define("frontend/validations/digital-input", ["exports", "ember-changeset-validations/validators", "frontend/validators/io-location"], function (_exports, _validators, _ioLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DigitInputValidations = _exports.AlarmDelayValidations = _exports.IdentityValidations = _exports.TrueFalseTextValidations = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TrueFalseTextValidations = {
    falseText: (0, _validators.validatePresence)({
      presence: true,
      message: 'Open Text should be present'
    }),
    trueText: (0, _validators.validatePresence)({
      presence: true,
      message: 'Closed Text should be present'
    })
  };
  _exports.TrueFalseTextValidations = TrueFalseTextValidations;
  var IdentityValidations = {
    name: (0, _validators.validatePresence)(true),
    input: [(0, _ioLocation.default)({
      scope: "digitalInputs"
    }), (0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      allowString: true,
      integer: true,
      gte: 0
    })]
  };
  _exports.IdentityValidations = IdentityValidations;
  var AlarmDelayValidations = {
    alarmDelay: (0, _validators.validateNumber)({
      allowString: true,
      integer: true,
      gte: 0
    })
  };
  _exports.AlarmDelayValidations = AlarmDelayValidations;

  var DigitInputValidations = _objectSpread(_objectSpread(_objectSpread({}, IdentityValidations), TrueFalseTextValidations), AlarmDelayValidations);

  _exports.DigitInputValidations = DigitInputValidations;
  var _default = DigitInputValidations;
  _exports.default = _default;
});