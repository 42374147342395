define("frontend/components/ui/list-items/modem", ["exports", "htt-ui/components/ui/list-items/modem"], function (_exports, _modem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _modem.default;
    }
  });
});