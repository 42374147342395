define("frontend/templates/customer/gpg-neighborhood/collectors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AYel1ytl",
    "block": "{\"symbols\":[\"installation\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"ui/search-input\",[],[[\"@onChange\",\"@value\"],[[32,0,[\"updateSearchString\"]],[32,0,[\"searchString\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"paper-content\",[[24,0,\"alarms-list flex-grow\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,0,[\"filteredInstallations\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,0,[\"isReplaceLoading\"]],[32,0,[\"isLoadingInitial\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"flex-30\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n        \"],[8,\"paper-progress-circular\",[],[[\"@accent\",\"@diameter\"],[true,70]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"paper-list\",[[24,0,\"flex-grow\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"filteredInstallations\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"lists/installation-list-item\",[],[[\"@installation\"],[[32,1]]],null],[2,\"\\n          \"],[8,\"paper-divider\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-grow layout-column layout-align-center-center\"],[12],[2,\"\\n      \"],[8,\"paper-subheader\",[[24,0,\"text-align-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"no_ITEM_to_display\"],[[\"item\"],[\"installations\"]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"or\",\"if\"]}",
    "moduleName": "frontend/templates/customer/gpg-neighborhood/collectors.hbs"
  });

  _exports.default = _default;
});