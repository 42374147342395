define("frontend/components/app-bars/hardware", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AppBars::Htt @title="Hardware" data-test-app-bars-hardware-header />
  <PaperTabs data-test-app-bars-hardware-tabs @selected={{@currentRoute}} @primary={{true}} @stretch={{true}} as |tabs|>
    <tabs.tab @value="hardware.rtus.index" @href={{href-to "hardware.rtus.index" }}>
      RTUs
    </tabs.tab>
    <tabs.tab @value="hardware.stackups.index" @href={{href-to "hardware.stackups.index" }}>
      Stackups
    </tabs.tab>
    <tabs.tab @value="hardware.modems.index" @href={{href-to "hardware.modems.index" }}>
      Modems
    </tabs.tab>
  </PaperTabs>
  */
  {
    "id": "+yHBlo8g",
    "block": "{\"symbols\":[\"tabs\",\"@currentRoute\"],\"statements\":[[8,\"app-bars/htt\",[],[[\"@title\"],[\"Hardware\"]],null],[2,\"\\n\"],[8,\"paper-tabs\",[],[[\"@selected\",\"@primary\",\"@stretch\"],[[32,2],true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware.rtus.index\",[30,[36,0],[\"hardware.rtus.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    RTUs\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware.stackups.index\",[30,[36,0],[\"hardware.stackups.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Stackups\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware.modems.index\",[30,[36,0],[\"hardware.modems.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Modems\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"href-to\"]}",
    "moduleName": "frontend/components/app-bars/hardware.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});