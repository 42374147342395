define("frontend/validators/analog-high-cal", ["exports", "frontend/utils/analog-sensor-types"], function (_exports, _analogSensorTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return function (key, _newValue, _oldValue, changes, content) {
      var highCal = Ember.get(changes, 'highCal'); // don't validate if not changed
      // changeset validations error on this.

      if (Ember.isNone(highCal)) return true;
      var sensorOut = Ember.get(changes, 'sensorOut') || Ember.get(content, 'sensorOut'); //no validation if sensorOut is of types

      if (_analogSensorTypes.default.isNotScaled(sensorOut)) return true; //error if lowVal is null

      if (Ember.isNone(highCal)) return "".concat(key, " must be set");
      return true;
    };
  }
});