define("frontend/components/alarms-download-button", ["exports", "htt-ui/components/alarms-download-button"], function (_exports, _alarmsDownloadButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _alarmsDownloadButton.default;
    }
  });
});