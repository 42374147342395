define("frontend/instance-initializers/refresh-page-on-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup("service:store");
    var globals = appInstance.lookup("service:globals");
    document.addEventListener("visibilitychange", function () {
      // With PWA if the app is in the background the customer information can become stale and not reload
      // Force a reload of customer data if PWA and on a customer route when the app comes into focus
      if (document.visibilityState === "visible" && globals.currentCustomer && window.matchMedia('(display-mode: standalone)').matches) {
        store.findRecord('customer', globals.currentCustomer.id, {
          reload: true,
          include: "gpg-neighborhoods.gpg-remote-installations.gpg-remotes,alarm-groups, installations.rtu"
        });
        store.query('alarm', {
          filter: {
            customer_id: globals.currentCustomer.id,
            active: true
          }
        });
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});