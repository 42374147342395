define("frontend/helpers/column-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.get-property
   */

  /**
   * Used in select input to get property or return obj if key is null
   * @method get-property
   * @return property of Ember object
   */
  var _default = Ember.Helper.helper(function (params)
  /*, hash*/
  {
    var rowData = params[0];
    var column = params[1];
    return column.columnData(rowData);
  });

  _exports.default = _default;
});