define("frontend/components/forms/technical-support-log", ["exports", "htt-ui/components/forms/technical-support-log"], function (_exports, _technicalSupportLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _technicalSupportLog.default;
    }
  });
});