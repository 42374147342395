define("frontend/validators/if-not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateIfNot = validateIfNot;
  _exports.validateIf = validateIf;

  function validateIfNot(attrs, validatorFunc) {
    var validator = function validator(key, newValue, oldValue, changes, content) {
      var truthies = attrs.filter(function (attr) {
        return changes && changes.hasOwnProperty(attr) ? Ember.get(changes, attr) : Ember.get(content, attr);
      });
      return truthies.length ? true : validatorFunc(key, newValue, oldValue, changes, content);
    };

    return validator;
  }

  function validateIf(attrs, validatorFunc) {
    var validator = function validator(key, newValue, oldValue, changes, content) {
      var truthies = attrs.filter(function (attr) {
        return changes && changes.hasOwnProperty(attr) ? Ember.get(changes, attr) : Ember.get(content, attr);
      });
      return truthies.length ? validatorFunc(key, newValue, oldValue, changes, content) : true;
    };

    return validator;
  }
});