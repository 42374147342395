define("frontend/utils/time-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.secondsToTimeString = secondsToTimeString;
  _exports.secondsToDurationString = secondsToDurationString;

  function secondsToTimeString(duration) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
    var seconds = 0,
        minutes = 0,
        hours = 0,
        results = "";
    seconds = precision > 0 ? duration % 60 : duration;
    seconds = Math.round(seconds);
    if (precision > 1) minutes = Math.floor(duration / 60);
    if (precision > 2) minutes = minutes % 60;
    if (precision > 2) hours = Math.floor(duration / (60 * 60));
    results = "".concat(seconds.toString().padStart(2, "0"));
    if (precision > 1) results = "".concat(minutes.toString().padStart(2, "0"), ":").concat(results);
    if (precision > 2) results = "".concat(hours.toString().padStart(2, "0"), ":").concat(results);
    return results;
  }

  function secondsToDurationString(duration) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
    var seconds = 0,
        minutes = 0,
        hours = 0,
        days = 0,
        results = "";
    seconds = precision > 1 ? duration % 60 : duration;
    if (precision > 1) minutes = Math.floor(duration / 60);
    if (precision > 2) minutes = minutes % 60;
    if (precision > 2) hours = Math.floor(duration / (60 * 60));
    if (precision > 3) hours = minutes % 24;
    if (precision > 3) days = Math.floor(duration / (60 * 60 * 24));
    if (seconds > 0) results = "".concat(Math.round(seconds), "s");
    if (minutes > 0) results = "".concat(minutes, "m ").concat(results);
    if (hours > 0) results = "".concat(hours, "h ").concat(results);
    if (days > 0) results = "".concat(days, "h ").concat(results);
    return results;
  }
});