define("frontend/components/app-bars/hardware-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AppBars::Htt @title="Hardware Types" data-test-app-bars-hardware-types-header />
  <PaperTabs data-test-app-bars-hardware-types-tabs @selected={{@currentRoute}} @primary={{true}} @stretch={{true}} as |tabs|>
    <tabs.tab @value="hardware-types.rtu-types.index" @href={{href-to "hardware-types.rtu-types.index" }}>
      Rtu Types
    </tabs.tab>
    <tabs.tab @value="hardware-types.stackup-types.index" @href={{href-to "hardware-types.stackup-types.index" }}>
      Stackup Types
    </tabs.tab>
    <tabs.tab @value="hardware-types.modem-types.index" @href={{href-to "hardware-types.modem-types.index" }}>
      Modem Types
    </tabs.tab>
    <tabs.tab @value="hardware-types.code-versions.index" @href={{href-to "hardware-types.code-versions.index" }}>
      Code Versions
    </tabs.tab>
  </PaperTabs>
  */
  {
    "id": "R7nu4bMc",
    "block": "{\"symbols\":[\"tabs\",\"@currentRoute\"],\"statements\":[[8,\"app-bars/htt\",[],[[\"@title\"],[\"Hardware Types\"]],null],[2,\"\\n\"],[8,\"paper-tabs\",[],[[\"@selected\",\"@primary\",\"@stretch\"],[[32,2],true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware-types.rtu-types.index\",[30,[36,0],[\"hardware-types.rtu-types.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Rtu Types\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware-types.stackup-types.index\",[30,[36,0],[\"hardware-types.stackup-types.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Stackup Types\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware-types.modem-types.index\",[30,[36,0],[\"hardware-types.modem-types.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Modem Types\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"tab\"]],[],[[\"@value\",\"@href\"],[\"hardware-types.code-versions.index\",[30,[36,0],[\"hardware-types.code-versions.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Code Versions\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"href-to\"]}",
    "moduleName": "frontend/components/app-bars/hardware-types.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});