define("frontend/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params)
  /*, hash*/
  {
    return params;
  });

  _exports.default = _default;
});