define("frontend/pods/account-group/sub-account-groups/account-group/controller", ["exports", "sentry-advisor/account-group/sub-account-groups/account-group/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _controller.default;
    }
  });
});