define("frontend/models/sentry-advisor-model", ["exports", "htt-shared-models/models/sentry-advisor-model"], function (_exports, _sentryAdvisorModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sentryAdvisorModel.default;
    }
  });
});