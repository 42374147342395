define("frontend/templates/customer/installations/details/inst/logs/new/technical-support-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ypsye0Sg",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"Create Tech Support Log\",[30,[36,0],[\"customer.installations.details.inst.logs\"],null]]],null],[2,\"\\n\"],[8,\"forms/technical-support-log\",[],[[\"@log\",\"@hideInstallationSelect\",\"@onSave\",\"@onCancel\"],[[32,1],true,[30,[36,0],[\"customer.installations.details.inst.logs\"],null],[30,[36,0],[\"customer.installations.details.inst.logs\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"replace-with\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/logs/new/technical-support-log.hbs"
  });

  _exports.default = _default;
});