define("frontend/models/digital-output-selector", ["exports", "htt-shared-models/models/digital-output-selector"], function (_exports, _digitalOutputSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _digitalOutputSelector.default;
    }
  });
});