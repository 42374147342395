define("frontend/templates/customer/installations/details/inst/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HkKWkEk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"isBaseRoute\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"installation-mobile-settings-header\",[],[[\"@installation\"],[[32,0,[\"installation\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/settings.hbs"
  });

  _exports.default = _default;
});