define("frontend/validations/alarm-group", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlarmGroupValidations = void 0;
  // @ts-ignore
  var AlarmGroupValidations = {
    name: (0, _validators.validatePresence)(true),
    installations: (0, _validators.validateLength)({
      max: 500
    })
  };
  _exports.AlarmGroupValidations = AlarmGroupValidations;
});