define("frontend/templates/customer/installations/details/inst/settings/telemetry-data/telemetry-data-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g7aVeODw",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app-bars/nested\",[],[[\"@onBack\",\"@title\"],[[30,[36,0],[\"customer.installations.details.inst.settings.telemetryData\"],null],\"Telemetry Data\"]],null],[2,\"\\n\\n\"],[8,\"paper-content\",[[24,0,\"flex-grow layout-column md-padding\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Thing ID\"],[13],[2,\"\\n    \"],[1,[32,0,[\"model\",\"thingId\"]]],[2,\"\\n\\n    \"],[10,\"h4\"],[12],[2,\"RTU Firmware Version\"],[13],[2,\"\\n    \"],[1,[32,0,[\"model\",\"rtuFirmwareVersion\"]]],[2,\"\\n\\n    \"],[10,\"h4\"],[12],[2,\"Gerenarated\"],[13],[2,\"\\n    \"],[1,[32,0,[\"model\",\"formattedGeneratedTime\"]]],[2,\"\\n\\n    \"],[10,\"h4\"],[12],[2,\"Received\"],[13],[2,\"\\n    \"],[1,[32,0,[\"model\",\"formattedInsertTime\"]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"model\",\"processedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h4\"],[12],[2,\"Processed\"],[13],[2,\"\\n      \"],[1,[32,0,[\"model\",\"formattedProcessedTime\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"failedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h4\"],[12],[2,\"Failed\"],[13],[2,\"\\n      \"],[1,[32,0,[\"model\",\"formattedFailedTime\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Data\"],[13],[2,\"\\n    \"],[10,\"pre\"],[12],[10,\"code\"],[12],[1,[32,0,[\"model\",\"deserializedDataPretty\"]]],[13],[13],[2,\"\\n\\n    \"],[10,\"h4\"],[12],[2,\"Logs\"],[13],[2,\"\\n    \"],[10,\"pre\"],[12],[10,\"code\"],[12],[1,[32,0,[\"model\",\"logsPretty\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"replace-with\",\"if\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/settings/telemetry-data/telemetry-data-detail.hbs"
  });

  _exports.default = _default;
});