define("frontend/components/forms/dashboard-element-reports/di-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::DigitalInput
    @changeset={{@changeset}}
    @attribute={{"digitalInput"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @diLabel={{"Digital Input"}}
  />
  */
  {
    "id": "/fz14Fwi",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/digital-input\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@diLabel\"],[[32,1],\"digitalInput\",[32,2],\"installation\",\"Digital Input\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/di-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});