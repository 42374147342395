define("frontend/config/ember-intl-analyzer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    whitelist: [/^countries\./, /^currency\./, /^validations\.errors\./, /^[^.]+\.warnings\.[^.]+$/]
  };
  _exports.default = _default;
});