define("frontend/helpers/default-if-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.default-if-empty
   */

  /**
   * Display the default text if the input is empty
   * @method properties-eq
   * @return boolean
   */
  // used in select-input for comparing by property
  var _default = Ember.Helper.helper(function (params) {
    var obj1 = params[0];
    var obj2 = params[1];

    if (Ember.isNone(obj1)) {
      return obj2;
    } else {
      return obj1;
    }
  });

  _exports.default = _default;
});