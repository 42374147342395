define("frontend/models/email-reports-subscription", ["exports", "htt-shared-models/models/email-reports-subscription"], function (_exports, _emailReportsSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emailReportsSubscription.default;
    }
  });
});