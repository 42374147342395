define("frontend/components/query-manager-view", ["exports", "query-manager/components/query-manager-view"], function (_exports, _queryManagerView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _queryManagerView.default;
    }
  });
});