define("frontend/helpers/attributes-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.properties-eq
   */

  /**
   * Used to check whether a property of an object is equal to another object's property
   * @method properties-eq
   * @return boolean
   */
  var _default = Ember.Helper.helper(function (params) {
    var modelAttrValue = params[0];
    var dropdownItem = params[1];
    var dropdownItemValueKey = params[2];
    var comparisonAttribute = params[3];
    var firstVal, secondVal;

    if (Ember.isPresent(modelAttrValue) && Ember.isPresent(dropdownItem)) {
      if (!(dropdownItem instanceof Ember.Object)) {
        dropdownItem = Ember.Object.create(dropdownItem);
      }

      if (Ember.isPresent(comparisonAttribute)) {
        firstVal = modelAttrValue.get(comparisonAttribute);
        secondVal = dropdownItem.get(comparisonAttribute);
      } else {
        firstVal = modelAttrValue;
        secondVal = dropdownItem.get(dropdownItemValueKey);
      }

      return firstVal === secondVal;
    }

    return false;
  });

  _exports.default = _default;
});