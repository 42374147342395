define("frontend/models/rtu", ["exports", "htt-shared-models/models/rtu"], function (_exports, _rtu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rtu.default;
    }
  });
});