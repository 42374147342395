define("frontend/helpers/combine-strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.combine-strings
   */

  /**
   * Merges strings of given parameters into one string.
   * @method combine-strings
   * @return Merged string of given parameters
   */
  var _default = Ember.Helper.helper(function (params)
  /*, hash*/
  {
    return params.join("");
  });

  _exports.default = _default;
});