define("frontend/templates/hardware/stackups/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e0T5/PJy",
    "block": "{\"symbols\":[\"codeVersion\",\"stackupType\",\"modem\",\"@model\"],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"New Stackup\",[32,0,[\"cancel\"]]]],null],[2,\"\\n\"],[8,\"paper-form\",[[24,0,\"md-padding layout-column flex\"]],[[\"@onSubmit\"],[[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex form-body\"],[12],[2,\"\\n\\n\"],[2,\"        \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@options\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],[32,4,[\"modems\"]],\"modem\",\"Modem\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,3,[\"httId\"]]],[2,\"\\n        \"]],\"parameters\":[3]}]]],[2,\"\\n\\n\"],[2,\"        \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@options\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],[32,4,[\"stackupTypes\"]],\"stackupType\",\"Stackup Type\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[2,\"        \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@options\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],[32,4,[\"codeVersions\"]],\"codeVersion\",\"Code Version\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[8,\"form-save-cancel-btns\",[],[[\"@onCancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/hardware/stackups/create.hbs"
  });

  _exports.default = _default;
});