define("frontend/instance-initializers/silence-deprecation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * @module Initializer
   */
  function initialize()
  /* application */
  {
    if (Ember.Debug && typeof Ember.Debug.registerDeprecationHandler === 'function') {
      Ember.Debug.registerDeprecationHandler(function (message, options, next) {
        if (options.id === "ember-views.dispatching-modify-property") return;
        next(message, options);
      });
    }
  }

  var _default = {
    name: 'silenceDeprecation',
    initialize: initialize
  };
  _exports.default = _default;
});