define("frontend/templates/customer/controls/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NcOPqX//",
    "block": "{\"symbols\":[\"controlGroup\"],\"statements\":[[6,[37,4],[[30,[36,3],[\"create controls\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"create-fab-button\",[],[[\"@onClick\"],[[32,0,[\"createControlGroup\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[32,0,[\"customer\",\"controlGroups\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"layout-row flex-wrap overflow-y-auto flex-grow\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"customer\",\"controlGroups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cards/control-group\",[[24,0,\"flex-25 flex-md-33 flex-sm-50 flex-xs-100\"]],[[\"@controlGroup\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,\"sytle\",\"font-size: 20px; margin: 8px;\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"noItems\"],[[\"items\"],[[30,[36,0],[\"controls\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"can\",\"if\"]}",
    "moduleName": "frontend/templates/customer/controls/index.hbs"
  });

  _exports.default = _default;
});