define("frontend/templates/customer/installations/details/inst/settings/telemetry-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KN4fJwHS",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,0,[\"isBaseRoute\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@hideSearch\",\"@listItemComponent\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"onItemClick\"]],[32,1],[32,0,[\"columns\"]],true,\"ui/list-items/telemetry-data-item\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/settings/telemetry-data.hbs"
  });

  _exports.default = _default;
});