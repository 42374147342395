define("frontend/highcharts-configs/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(defaultOptions) {
    defaultOptions.credits.enabled = false;
    defaultOptions.global.useUTC = true;
    defaultOptions.global.timezoneOffset = 0;
    return defaultOptions;
  }
});