define("frontend/templates/customer/controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gNzCHTDz",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[2,\"\\n\"],[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"customer\"]],\"Controls Settings\"]],null],[2,\"\\n\\n\"],[8,\"paper-tabs\",[],[[\"@selected\",\"@stretch\",\"@onChange\",\"@primary\"],[[32,0,[\"currentTab\"]],true,[30,[36,1],[[30,[36,0],[[32,0,[\"currentTab\"]]],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"tab\"]],[],[[\"@primary\",\"@class\",\"@value\",\"@href\"],[true,\"flex color-white\",\"customer.controls.index\",[30,[36,2],[\"customer.controls.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,3],[\"controlGroup\"],[[\"count\"],[1]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\" \\n      \"],[8,[32,1,[\"tab\"]],[],[[\"@class\",\"@value\",\"@href\"],[\"flex color-white\",\"customer.controls.pump-groups\",[30,[36,2],[\"customer.controls.pump-groups\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,3],[\"pumpGroup.many\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"page-content\",[],[[\"@padding\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"href-to\",\"t\",\"-outlet\",\"component\"]}",
    "moduleName": "frontend/templates/customer/controls.hbs"
  });

  _exports.default = _default;
});