define("frontend/validations/code-version", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CodeVersionValidations = {
    name: (0, _validators.validatePresence)(true),
    versionNumber: (0, _validators.validatePresence)(true)
  };
  var _default = CodeVersionValidations;
  _exports.default = _default;
});