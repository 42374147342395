define("frontend/components/center-map-marker-popup", ["exports", "htt-ui/components/center-map-marker-popup"], function (_exports, _centerMapMarkerPopup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _centerMapMarkerPopup.default;
    }
  });
});