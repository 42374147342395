define("frontend/templates/hardware/modems/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3DoMfoPQ",
    "block": "{\"symbols\":[\"modemType\",\"@model\"],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"New Modem\",[32,0,[\"cancel\"]]]],null],[2,\"\\n\"],[8,\"paper-form\",[[24,0,\"md-padding layout-column flex\"]],[[\"@onSubmit\"],[[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex form-body\"],[12],[2,\"\\n        \"],[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],\"serialNumber\",\"Serial Number\"]],null],[2,\"\\n\\n        \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@options\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],[32,2,[\"modemTypes\"]],\"modemType\",\"Modem Type\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n\\n        \"],[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],\"httId\",\"HTT ID\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[8,\"form-save-cancel-btns\",[],[[\"@onCancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/hardware/modems/create.hbs"
  });

  _exports.default = _default;
});