define("frontend/components/ui/dialogs/alarm-notification-setting-edit", ["exports", "htt-ui/components/ui/dialogs/alarm-notification-setting-edit"], function (_exports, _alarmNotificationSettingEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _alarmNotificationSettingEdit.default;
    }
  });
});