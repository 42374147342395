define("frontend/components/forms/htt-controls/triggers/time-interval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Inputs::Text
    @changeset={{@changeset}}
    @attribute={{"label"}}
    @label={{"label"}}
    />
  <Ui::Inputs::Checkbox
    @changeset={{@changeset}}
    @attribute={{'repeat'}}
    @label={{"Repeat"}}
    />
  <Ui::Selects::Minutes
    @changeset={{@changeset}}
    @attribute={{'time'}}
    @label={{"Time"}}
    />
  <Ui::Selects::TimeZone
    @changeset={{@changeset}}
    @attribute={{'timezone'}}
    @label={{"Timezone"}}
    />
  <Ui::Selects::DaysOfWeek
    @changeset={{@changeset}}
    @attribute={{'days_of_week'}}
    @label={{"Days of week"}}
    />
  */
  {
    "id": "9wCacubU",
    "block": "{\"symbols\":[\"@changeset\"],\"statements\":[[8,\"ui/inputs/text\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"label\",\"label\"]],null],[2,\"\\n\"],[8,\"ui/inputs/checkbox\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"repeat\",\"Repeat\"]],null],[2,\"\\n\"],[8,\"ui/selects/minutes\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"time\",\"Time\"]],null],[2,\"\\n\"],[8,\"ui/selects/time-zone\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"timezone\",\"Timezone\"]],null],[2,\"\\n\"],[8,\"ui/selects/days-of-week\",[],[[\"@changeset\",\"@attribute\",\"@label\"],[[32,1],\"days_of_week\",\"Days of week\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/htt-controls/triggers/time-interval.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});