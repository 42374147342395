define("frontend/templates/customer/logs/service-log/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qzp/6PE7",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"Edit Log\",[30,[36,0],[\"customer.logs.service-log\"],null]]],null],[2,\"\\n\"],[8,\"forms/service-log\",[],[[\"@log\",\"@onSave\",\"@onCancel\"],[[32,1],[30,[36,0],[\"customer.logs.service-log\"],null],[30,[36,0],[\"customer.logs.service-log\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"replace-with\"]}",
    "moduleName": "frontend/templates/customer/logs/service-log/edit.hbs"
  });

  _exports.default = _default;
});