define("frontend/validations/analog-output-calibration", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    low: (0, _validators.validateNumber)({
      allowBlank: false
    }),
    high: (0, _validators.validateNumber)({
      allowBlank: false
    }),
    min: (0, _validators.validateNumber)({
      allowBlank: true
    }),
    max: (0, _validators.validateNumber)({
      allowBlank: true
    })
  };
  _exports.default = _default;
});