define("frontend/templates/customer/gpg-neighborhoods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4GmZKDhw",
    "block": "{\"symbols\":[],\"statements\":[[8,\"maps/gpg-neighborhoods-map\",[],[[\"@customer\",\"@selectedNeighborhood\"],[[32,0,[\"customer\"]],[32,0,[\"selectedNeighborhood\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/customer/gpg-neighborhoods.hbs"
  });

  _exports.default = _default;
});