define("frontend/validations/counter-calibration", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    m: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)(true)],
    b: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)(true)],
    units: (0, _validators.validatePresence)(true),
    precisionValue: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)(true)]
  };
  _exports.default = _default;
});