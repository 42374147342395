define("frontend/components/sub-forms/change-per-pulse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperList>
    <PaperItem>
      <PaperInputWithErrors @label="m" @model={{config}} @attributeName="m" @type="number" />
    </PaperItem>
    <PaperItem>
      <Ui::Inputs::Datepicker @class="flex-grow" @label={{t "retroactiveDate"}} @allowClear={{true}} @maxDate={{moment (if-inplace config.datetime now)}} @datetime={{config.retroactiveDatetime}} @onChange={{action (mut config.retroactiveDatetime)}} @validationErrorMessages={{config.validations.attrs.retroactiveDatetime.messages}} />
    </PaperItem>
    <PaperItem>
      <span class="flex-grow"></span>
      <PaperButton @label={{t "cancel"}} @onClick={{cancel}} />
      <PaperButton @primary={{true}} @disabled={{not config.validations.isValid}} @label={{t "save"}} @onClick={{save}} />
    </PaperItem>
  </PaperList>
  */
  {
    "id": "sxdKa9ra",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-input-with-errors\",[],[[\"@label\",\"@model\",\"@attributeName\",\"@type\"],[\"m\",[34,0],\"m\",\"number\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui/inputs/datepicker\",[],[[\"@class\",\"@label\",\"@allowClear\",\"@maxDate\",\"@datetime\",\"@onChange\",\"@validationErrorMessages\"],[\"flex-grow\",[30,[36,1],[\"retroactiveDate\"],null],true,[30,[36,4],[[30,[36,3],[[35,0,[\"datetime\"]],[35,2]],null]],null],[34,0,[\"retroactiveDatetime\"]],[30,[36,6],[[32,0],[30,[36,5],[[35,0,[\"retroactiveDatetime\"]]],null]],null],[34,0,[\"validations\",\"attrs\",\"retroactiveDatetime\",\"messages\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"paper-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@label\",\"@onClick\"],[[30,[36,1],[\"cancel\"],null],[34,7]]],null],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@primary\",\"@disabled\",\"@label\",\"@onClick\"],[true,[30,[36,8],[[35,0,[\"validations\",\"isValid\"]]],null],[30,[36,1],[\"save\"],null],[34,9]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"config\",\"t\",\"now\",\"if-inplace\",\"moment\",\"mut\",\"action\",\"cancel\",\"not\",\"save\"]}",
    "moduleName": "frontend/components/sub-forms/change-per-pulse.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});