define("frontend/validations/alarm-group-contact-link", ["exports", "ember-changeset-validations/validators", "frontend/validators/if-not", "frontend/validators/contact-method-qualifier"], function (_exports, _validators, _ifNot, _contactMethodQualifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore
  var _default = {
    contact: (0, _validators.validatePresence)(true),
    contactMethodQualifier: (0, _contactMethodQualifier.default)(),
    nagInterval: (0, _ifNot.validateIf)(["nag"], (0, _validators.validateNumber)({
      gte: 1,
      presence: true,
      allowString: true,
      allowBlank: false
    })),
    alarmLevel: (0, _validators.validateNumber)({
      gte: 0,
      presence: true,
      allowString: true,
      allowBlank: false
    }),
    sendToType: (0, _validators.validatePresence)({
      presence: true,
      allowString: true,
      allowBlank: false
    })
  };
  _exports.default = _default;
});