define("frontend/templates/hardware/modems/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6fmyvvwU",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"flex layout-column\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"main\"],[12],[2,\"\\n  \"],[8,\"app-bars/hardware\",[],[[\"@currentRoute\"],[\"hardware.modems.index\"]],null],[2,\"\\n  \"],[8,\"query-manager-view\",[],[[\"@queryManager\",\"@columns\",\"@onCreate\",\"@listItemComponent\",\"@onItemClick\"],[[32,1,[\"queryManager\"]],[32,1,[\"columns\"]],[30,[36,0],[\"hardware.modems.create\"],null],[30,[36,1],[\"ui/list-items/modem\"],null],[32,0,[\"onItemClick\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"transition-to\",\"component\"]}",
    "moduleName": "frontend/templates/hardware/modems/index.hbs"
  });

  _exports.default = _default;
});