define("frontend/templates/customer/alarms/alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZpYoesJZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"alarm-details\",[],[[\"@alarm\",\"@transitionUp\",\"@showLocation\"],[[32,0,[\"model\"]],[32,0,[\"transitionUp\"]],true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/customer/alarms/alarm.hbs"
  });

  _exports.default = _default;
});