define("frontend/helpers/capitalize-words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.capitalize-words
   */

  /**
   * Capitalizes string.
   * @method capitalize
   * @return number
   */
  // To DO: Investigate 
  var _default = Ember.Helper.helper(function (params) {
    return params[0].split(' ').map(function (str) {
      return str.capitalize();
    }).join(' ');
  });

  _exports.default = _default;
});