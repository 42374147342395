define("frontend/validators/analog-threshold-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return function (key, newValue, _oldValue, changes, content) {
      var thresholdLevel = parseFloat(newValue);
      var settingsMax = Ember.get(changes, 'settingsMax') || Ember.get(content, 'settingsMax');
      var settingsMin = Ember.get(changes, 'settingsMin') || Ember.get(content, 'settingsMin');
      var thresholdNumber = key.charAt(9);
      var active = Ember.get(changes, "threshold".concat(thresholdNumber, "Active")) || Ember.get(content, "threshold".concat(thresholdNumber, "Active"));
      if (active === false) return true;
      if (Ember.isNone(thresholdLevel)) return true;

      if (thresholdLevel > settingsMax) {
        return "Threshold must be less than ".concat(settingsMax);
      }

      if (thresholdLevel < settingsMin) {
        return "Threshold must be more than ".concat(settingsMin);
      }

      return true;
    };
  }
});