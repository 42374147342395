define("frontend/components/tab-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash content="No tabs to display")}}
  */
  {
    "id": "J7+lSkQw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"content\"],[\"No tabs to display\"]]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "frontend/components/tab-items.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});