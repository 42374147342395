define("frontend/templates/hardware-types/stackup-types/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fyx5oAQq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex layout-column\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"main\"],[12],[2,\"\\n  \"],[8,\"app-bars/hardware-types\",[],[[\"@currentRoute\"],[\"hardware-types.stackup-types.index\"]],null],[2,\"\\n  \"],[8,\"query-manager-view\",[],[[\"@listView\",\"@queryManager\",\"@columns\",\"@onCreate\",\"@listItemComponent\",\"@onItemClick\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"model\",\"queryManager\"]],[32,0,[\"model\",\"columns\"]],[30,[36,0],[\"hardware-types.stackup-types.create\"],null],[30,[36,1],[\"ui/list-items/stackup-type-display\"],null],[32,0,[\"onItemClick\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\" \"]],\"hasEval\":false,\"upvars\":[\"transition-to\",\"component\"]}",
    "moduleName": "frontend/templates/hardware-types/stackup-types/index.hbs"
  });

  _exports.default = _default;
});