define("frontend/templates/hardware/rtus/rtu/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oXBg311f",
    "block": "{\"symbols\":[\"stackup\",\"rtuType\",\"@model\"],\"statements\":[[8,\"app-bars/form\",[],[[\"@title\",\"@onBack\"],[\"Edit Rtu\",[32,0,[\"cancel\"]]]],null],[2,\"\\n\"],[8,\"paper-form\",[[24,0,\"md-padding layout-column flex\"]],[[\"@onSubmit\"],[[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex form-body\"],[12],[2,\"\\n\\n        \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@options\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],[32,3,[\"rtuTypes\"]],\"rtuType\",\"RTU Type\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n\\n        \"],[8,\"ui/selects/generic\",[],[[\"@changeset\",\"@options\",\"@optionsField\",\"@attribute\",\"@label\"],[[32,0,[\"changeset\"]],[32,3,[\"stackups\"]],\"httId\",\"stackup\",\"Stackup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"httId\"]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[8,\"form-save-cancel-btns\",[],[[\"@onCancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/templates/hardware/rtus/rtu/edit.hbs"
  });

  _exports.default = _default;
});