define("frontend/helpers/underscore-to-space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.underscore-to-space
   */

  /**
   * Takes string with underscores and replaces it with spaces.
   * @method underscore-to-space
   * @return number
   */
  var _default = Ember.Helper.helper(function (params) {
    return params[0].replace(/_/g, ' ');
  });

  _exports.default = _default;
});