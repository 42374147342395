define("frontend/utils/highcharts-option-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setDefaultHighChartOptions = setDefaultHighChartOptions;
  var localConfig = null;
  var defaultOptions = {
    plotOptions: {
      series: {
        shadow: false
      }
    },
    // since javascript timestamp is unreliable due to it changing based on browser location, we set the highchart's timezone
    // to be UTC instead of browser's local timezone.
    global: {
      timezoneOffset: 0
    },
    credits: {
      enabled: false
    }
  };

  function setDefaultHighChartOptions()
  /* owner */
  {
    if (!localConfig) {
      {
        localConfig = defaultOptions;
      }
    }

    Highcharts.setOptions(localConfig);
  }
});