define("frontend/helpers/properties-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.properties-eq
   */

  /**
   * Used to check whether a property of an object is equal to another object's property
   * @method properties-eq
   * @return boolean
   */
  // used in select-input for comparing by property
  var _default = Ember.Helper.helper(function (params) {
    var obj1 = params[0];
    var obj2 = params[1];
    var comparisonProperty = params[2]; // if comparisonProperty is not set, just compare the two parameters

    if (Ember.isNone(comparisonProperty) || Ember.isNone(obj1) || Ember.isNone(obj2)) {
      return obj1 === obj2;
    } else {
      // otherwise, get the property from each parameter and compare the two
      return obj1.get(comparisonProperty) === obj2.get(comparisonProperty);
    }
  });

  _exports.default = _default;
});