define("frontend/components/forms/dashboard-element-reports/counter-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"counter"}}
    @customer={{@customer}}
  />
  */
  {
    "id": "kPh0FFxM",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"counter\",[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/counter-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});