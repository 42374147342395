define("frontend/validations/htt-controls/triggers/get-group-state", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    label: (0, _validators.validatePresence)({
      presence: true
    }),
    key: (0, _validators.validatePresence)({
      presence: true
    })
  };
  _exports.default = _default;
});