define("frontend/mixins/change-serializer", ["exports", "ember-data-change-tracker/mixins/keep-only-changed"], function (_exports, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _keepOnlyChanged.default;
    }
  });
});