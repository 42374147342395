define("frontend/validators/contact-method-qualifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return function (_key, newValue, _oldValue, changes, content) {
      var contactMethodType = Ember.get(changes, "contactMethod.constructor.modelName") || Ember.get(content, "contactMethod.constructor.modelName");
      var isValid = contactMethodType !== "phone-number" || Ember.isPresent(newValue);
      return isValid || "must have contact method";
    };
  }
});