define("frontend/models/query-manager-test-model", ["exports", "query-manager/models/query-manager-test-model"], function (_exports, _queryManagerTestModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _queryManagerTestModel.default;
    }
  });
});