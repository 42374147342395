define("frontend/models/pump-manufacturer", ["exports", "htt-shared-models/models/pump-manufacturer"], function (_exports, _pumpManufacturer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pumpManufacturer.default;
    }
  });
});