define("frontend/initializers/warnings-to-ignore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var IGNORED_WARNINGS = ["ds.store.push-link-for-sync-relationship"];

  function ignoreWarning(options) {
    return options && options.id && IGNORED_WARNINGS.includes(options.id);
  }

  function initialize() {
    Ember.Debug.registerWarnHandler(function (_message, options, next) {
      if (!ignoreWarning(options)) {
        next.apply(void 0, arguments);
      }
    });
  }

  var _default = {
    name: "warnings-to-ignore",
    initialize: initialize
  };
  _exports.default = _default;
});