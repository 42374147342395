define("frontend/components/forms/dashboard-element-reports/analog-daily-statistic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Analog
    @changeset={{@changeset}}
    @attribute={{"analog"}}
    @customer={{@customer}}
  />
  */
  {
    "id": "9oMhdEDV",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/analog\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"analog\",[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/analog-daily-statistic.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});