define("frontend/validations/event", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validateOthersField() {
    return function (key, newValue, _oldValue, changes, content) {
      /**
       * 
       * if the cause category is 'Others'({id: 1}), then causeTag should be valid
       * and check for any changes made to `causeCategory` on changeset first, and then on actual model
       * 
       */
      var cause_id = Ember.get(changes, 'causeCategory.id') || content.get('causeCategory.id');
      /* 
       * return `true` if valid || error message string if invalid
       * 
       */

      return cause_id == "1" ? (0, _validators.validatePresence)(true)(key, newValue) : true;
    };
  }

  var _default = {
    causeCategory: (0, _validators.validatePresence)(true),
    causeTag: [validateOthersField()]
  };
  _exports.default = _default;
});