define("frontend/components/forms/dashboard-element-reports/wv-mor-ew210", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Analog
    @changeset={{@changeset}}
    @attribute={{"analog"}}
    @customer={{@customer}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"water_meter_1"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"water meter 1"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"water_meter_2"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"water meter 2"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"water_meter_3"}}
    @customer={{@customer}}
    @label={{"water meter 3"}}
    @instLabel={{"installation"}}
  />
  
  */
  {
    "id": "zR9RYP2h",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/analog\",[],[[\"@changeset\",\"@attribute\",\"@customer\"],[[32,1],\"analog\",[32,2]]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"water_meter_1\",[32,2],\"installation\",\"water meter 1\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"water_meter_2\",[32,2],\"installation\",\"water meter 2\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@label\",\"@instLabel\"],[[32,1],\"water_meter_3\",[32,2],\"water meter 3\",\"installation\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/wv-mor-ew210.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});