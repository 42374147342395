define("frontend/templates/customer/installations/details/inst/rtu-configs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L10O3gE/",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"globals\",\"isSmall\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"installation-mobile-settings-header\",[],[[\"@installation\"],[[32,0,[\"installation\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"layout-row md-padding\"],[12],[2,\"\\n    \"],[8,\"paper-button\",[[4,[38,2],[\"click\",[32,0,[\"toggleCurrentConfig\"]]],null]],[[\"@raised\",\"@accent\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"Show Current Config\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"showCurrentConfig\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-modals/show-current-config\",[],[[\"@onClose\"],[[32,0,[\"toggleCurrentConfig\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"query-manager-view\",[],[[\"@listView\",\"@onItemClick\",\"@queryManager\",\"@columns\",\"@hideSearch\",\"@filterComponent\",\"@listItemComponent\"],[[32,0,[\"globals\",\"isSmall\"]],[32,0,[\"onItemClick\"]],[32,1],[32,0,[\"columns\"]],true,\"rtu-configs-filters\",\"ui/list-items/rtu-config-item\"]],null],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"if\",\"on\",\"-outlet\",\"component\"]}",
    "moduleName": "frontend/templates/customer/installations/details/inst/rtu-configs/index.hbs"
  });

  _exports.default = _default;
});