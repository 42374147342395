define("frontend/validations/technical-support-logs", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TechnicalSupportLogValidations = {
    action: (0, _validators.validatePresence)(true),
    creator: (0, _validators.validatePresence)(true),
    customer: (0, _validators.validatePresence)(true)
  };
  var _default = TechnicalSupportLogValidations;
  _exports.default = _default;
});