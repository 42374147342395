define("frontend/components/ui/selects/minutes", ["exports", "htt-ui/components/ui/selects/minutes"], function (_exports, _minutes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _minutes.default;
    }
  });
});