define("frontend/components/forms/dashboard-element-reports/bangham-fr-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"meter_1"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"whiteaker springs"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"meter_2"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"fisk road"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"meter_3"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"hwy 136"}}
  />
  <Ui::Selects::Counter
    @changeset={{@changeset}}
    @attribute={{"meter_4"}}
    @customer={{@customer}}
    @instLabel={{"installation"}}
    @label={{"zone 4"}}
  />
  */
  {
    "id": "qz5tN5kE",
    "block": "{\"symbols\":[\"@changeset\",\"@customer\"],\"statements\":[[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"meter_1\",[32,2],\"installation\",\"whiteaker springs\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"meter_2\",[32,2],\"installation\",\"fisk road\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"meter_3\",[32,2],\"installation\",\"hwy 136\"]],null],[2,\"\\n\"],[8,\"ui/selects/counter\",[],[[\"@changeset\",\"@attribute\",\"@customer\",\"@instLabel\",\"@label\"],[[32,1],\"meter_4\",[32,2],\"installation\",\"zone 4\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "frontend/components/forms/dashboard-element-reports/bangham-fr-report.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});