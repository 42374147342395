define("frontend/templates/customer/create-pump-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZktvzJgn",
    "block": "{\"symbols\":[\"top\",\"bottom\"],\"statements\":[[8,\"app-bars/customer\",[],[[\"@customer\",\"@title\"],[[32,0,[\"model\",\"customer\"]],[30,[36,2],[[30,[36,1],[[32,0,[\"model\",\"label\"]]],null],[30,[36,0],[\"pumpGroup.one\"],null]],null]]],null],[2,\"\\n\"],[8,\"page-content\",[],[[\"@padding\"],[\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"controls/pump-group\",[],[[\"@hasControls\",\"@pumpGroup\",\"@editing\"],[[30,[36,3],[[32,0,[\"globals\",\"isMobile\"]]],null],[32,0,[\"model\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n      \"],[8,\"paper-button\",[[16,\"onClick\",[32,0,[\"onCancel\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          clear\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,0],[\"cancel\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"paper-button\",[],[[\"@primary\",\"@raised\",\"@onClick\"],[true,true,[32,0,[\"onSave\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-icon\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          check\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,0],[\"save\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"titleize\",\"or\",\"not\",\"if\"]}",
    "moduleName": "frontend/templates/customer/create-pump-group.hbs"
  });

  _exports.default = _default;
});