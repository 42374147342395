define("frontend/validators/password-strength", ["exports", "zxcvbn"], function (_exports, _zxcvbn2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateStrength = validateStrength;

  // @ts-ignore
  // validators/custom.js
  function validateStrength() {
    var targetScore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
    return function (_key, newValue)
    /* , _oldValue: any, _changes: any, _content: any */
    {
      // validation logic`
      // return `true` if valid || error message string if invalid
      var strengthLevels = ['Very Poor', 'Poor', 'Fair', 'Good', 'Excellent'];

      var _zxcvbn = (0, _zxcvbn2.default)(newValue),
          score = _zxcvbn.score;

      return score >= targetScore || "Password is too weak";
    };
  }
});