define("frontend/helpers/convert-newline-to-br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Helper
   */

  /**
   * @class Helper.convert-newline
   */
  var _default = Ember.Helper.helper(function (params) {
    if (Ember.isNone(params[0])) {
      return params[0];
    } else {
      var strings = params[0].replace(/(?:\r\n|\r|\n)/g, '<br/>');
      return Ember.String.htmlSafe(strings);
    }
  });

  _exports.default = _default;
});