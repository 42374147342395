define("frontend/validations/service-log", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceLogValidations = {
    datetime: (0, _validators.validatePresence)(true),
    servicer: (0, _validators.validatePresence)(true),
    installation: (0, _validators.validatePresence)(true),
    action: (0, _validators.validatePresence)(true)
  };
  var _default = ServiceLogValidations;
  _exports.default = _default;
});