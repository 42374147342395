define("frontend/components/center-map-marker", ["exports", "htt-ui/components/center-map-marker"], function (_exports, _centerMapMarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _centerMapMarker.default;
    }
  });
});